import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import getSymbolFromCurrency from "currency-symbol-map";
import validator from "validator";
import Stack from "@mui/material/Stack";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/Remove";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteOutline";
import TextField from "@mui/material/TextField";
import { getCountry } from "../../data/localSettingsActions";
import Utils from "../../core/Utils";
import { serviceItemActionTypes } from "../reducers/serviceItem/serviceItemActionTypes";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import DecimalEntryField from "./controls/DecimalEntryField";
import { getTaxAmount, getTotalPrice } from "../../helpers/productHelper";
import { OfferTypes } from "../../core/Constants";
import { FormControl, TableRow, TableCell } from "@mui/material";
import ComplimentaryPopup from "./Complimentary";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ItemDiscountComponent from "./ItemDiscountComponent";
import CompValueEntryField from "./controls/CompValueEntryField";
import CloseIcon from "@mui/icons-material/Close";

const actions = {
  incrementAction: "incrementAction",
  decrementAction: "decrementAction",
  editAction: "editAction"
};

const PriceComponent = ({ price, currency, handleActions }) => {
  const handleEditPrice = (value) => {
    handleAction(actions.editAction, value);
  };
  const handleAction = (action, value) => {
    switch (action) {
      case actions.decrementAction:
        var oldPrice = Utils.ConvertToFloat(price);
        if (oldPrice < 1 || oldPrice === 0 || oldPrice === 0.0 || oldPrice === 0.00) {
          return;
        }
        handleActions(Utils.ConvertToFloat((oldPrice - 1.00).toFixed(2)));
        break;
      case actions.incrementAction:
        var oldPrice = Utils.ConvertToFloat(price);
        handleActions(Utils.ConvertToFloat((oldPrice + 1.00).toFixed(2)));
        break;
      case actions.editAction:
        handleActions(value);
        break;
    }
  };
  return (
    <Grid xs={8} lg={2} className="text-right" alignSelf="center">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ marginRight: "20px" }}
      >
        <Button
          onClick={() => handleAction(actions.decrementAction)}
          className="price-btn btn-left"
        >
          <RemoveCircleOutlineIcon fontSize="large" alignSelf="center" />
        </Button>
        <Stack direction="row" alignItems="center" style={{ margin: "0" }}>
          <Typography
            level="h6"
            component="h6"
            fontWeight="700"
            fontSize="12px"
            className="currency-symbol"
            style={{ marginRight: "15px" }}
          >
            {getSymbolFromCurrency(currency)}
          </Typography>
          <DecimalEntryField
            fontWeight="600"
            setValue={handleEditPrice}
            className="inputField text-right"
            padding="0"
            sx={{
              wordBreak: "break-word",
              whiteSpace: "normal",
              overflowWrap: "break-word"
            }}
            style={{ "max-width": "150px", margin: 0 }}
            value={price}
            convertToFloat={false}
          />
        </Stack>
        <Button
          onClick={() => handleAction(actions.incrementAction)}
          className="price-btn btn-right"
        >
          <AddCircleOutlineIcon fontSize="large" alignSelf="center" />
        </Button>
      </Stack>
    </Grid>
  );
}

const QuantityComponent = ({ quantity, handleActions }) => {
  const handleEditQuantity = (value) => {
    handleAction(actions.editAction, value);
  };
  const handleAction = (action, value) => {
    switch (action) {
      case actions.decrementAction:
        var oldQuantity = Utils.ConvertToFloat(quantity);
        if (oldQuantity < 1) {
          return;
        }
        handleActions(Utils.ConvertToFloat((oldQuantity - 1).toFixed(2)));
        break;
      case actions.incrementAction:
        var oldQuantity = Utils.ConvertToFloat(quantity);
        handleActions(Utils.ConvertToFloat((oldQuantity + 1).toFixed(2)));
        break;
      case actions.editAction:
        handleActions(value);
        break;
    }
  };
  return (
    <Grid xs={4} lg={2} alignSelf="center">
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          onClick={() => handleAction(actions.decrementAction)}
          className="price-btn btn-left"
        >
          <RemoveCircleOutlineIcon fontSize="large" alignSelf="center" />
        </Button>
        <DecimalEntryField
          label=""
          type="text"
          alignSelf="center"
          variant="outlined"
          value={quantity}
          setValue={handleEditQuantity}
          className="inputField text-right"
          style={{ width: "80px", margin: 0 }}
          max={0}
        />
        <Button
          onClick={() => handleAction(actions.incrementAction)}
          className="price-btn btn-right"
        >
          <AddCircleOutlineIcon fontSize="large" alignSelf="center" />
        </Button>
      </Stack>
    </Grid>
  );
}
 
const ServiceItem = ({ serviceItem, index, handleAction,
  itemComplimentaryList,
  isDiscount,
  onAddComp,
  onRemoveComp,  
  onEditComp,
  taxIncluded, }) => {
  const [countryInfo, setCountryInfo] = useState({});
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(0);

  //Compliment hooks
  const [isViewDiscountIcon, setIsViewDiscountIcon] = useState(false);
  const [showComplimentary, setShowComplimentary] = useState(false);
  const [compType, setCompType] = useState("Discount");
  const [compValue, setCompValue] = useState();
  const [currentComp, setCurrentComp] = useState({});
  const [maxPrice, setMaxPrice] = useState(serviceItem.TotalPrice);
  const [description, setDescription] = useState(serviceItem.Description);


  useEffect(() => {
    initializeComponent();
  }, []);

  const initializeComponent = () => {
    setCountryInfo(getCountry());
  }

  useEffect(() => {
    setMaxPrice(compType === "Discount" ? 100 : serviceItem.TotalPrice);
  }, [compType]);

  useEffect(() => {
    if (Utils.IsNullOrEmptyObject(serviceItem)) {
      return;
    }
    setPrice(serviceItem.Price.toFixed(2));
    setQuantity(serviceItem.Quantity);
    var canAddDiscount =
      Utils.IsNullOrEmptyArray(itemComplimentaryList) ||
      Utils.IsNullOrEmptyArray(
        itemComplimentaryList.filter((i) => i.index === serviceItem.Sequence)
      );
    setIsViewDiscountIcon(canAddDiscount);
    if (!canAddDiscount) {
      setCurrentComp(
        itemComplimentaryList.find((i) => i.index === serviceItem.Sequence)
      );
    }
    var defaultType = "Discount";
    setCompType(defaultType)
    setCompValue("");
    setDescription(serviceItem.Description);
  }, [serviceItem]);

  const handleRemove = () => {
    var action = {
      type: serviceItemActionTypes.Delete,
      value: index,
    };
    handleAction(action);
  };

  const handlePrice = (newValue, pAction) => {
    switch (pAction) {
      case actions.decrementAction:
      case actions.incrementAction:
        if (!Utils.IsNullOrEmpty(newValue)) {
          newValue = newValue.toFixed(2);
        }
        break;
    }
    var newPrice = Utils.ConvertToFloat(newValue);
    setPrice(newValue);
    if (newPrice === 0) {
      serviceItem.Price = newPrice;
      serviceItem.TotalPrice = 0;
      serviceItem.TaxAmount = 0;
      serviceItem.TotalPriceIncludingTax = 0;
    } else {
      serviceItem.Price = newPrice;
      serviceItem.TotalPrice = serviceItem.Price * serviceItem.Quantity;
      recalculateDiscountOnPriceOrQuantityChange();
      var totalPrice = serviceItem.TotalPrice - serviceItem.DiscountTotal;
      serviceItem.TaxAmount = (totalPrice * serviceItem.TaxPercent) / 100;
      serviceItem.TotalPriceIncludingTax = totalPrice + serviceItem.TaxAmount;
    }
    setMaxPrice(compType === "Discount" ? 100 : serviceItem.TotalPrice);
    var action = {
      type: serviceItemActionTypes.Edit,
      value: serviceItem,
    };
    handleAction(action);
  };

  const handleQuantity = (newValue) => {
    var newQty = Utils.ConvertToFloat(newValue);
    setQuantity(newValue);
    if (newQty === 0) {
      serviceItem.Quantity = "";
      serviceItem.TotalPrice = 0;
      serviceItem.TaxAmount = 0;
      serviceItem.TotalPriceIncludingTax = 0;
    } else {
      serviceItem.Quantity = newQty;
      serviceItem.TotalPrice = serviceItem.Price * serviceItem.Quantity;
      recalculateDiscountOnPriceOrQuantityChange();
      var totalPrice = serviceItem.TotalPrice - serviceItem.DiscountTotal;
      serviceItem.TaxAmount = (totalPrice * serviceItem.TaxPercent) / 100;
      serviceItem.TotalPriceIncludingTax = totalPrice + serviceItem.TaxAmount;
    }
    setMaxPrice(compType === "Discount" ? 100 : serviceItem.TotalPrice);
    var action = {
      type: serviceItemActionTypes.Edit,
      value: serviceItem,
    };
    handleAction(action);
  };

  const handleCloseComplimentary = () => {
    setShowComplimentary(false);
    setCompValue("");
  };

  const recalculateDiscountOnPriceOrQuantityChange = () => {
    if (
      Utils.IsNullOrEmptyArray(serviceItem.Discounts) ||
      Utils.IsNullOrEmptyArray(itemComplimentaryList) ||
      Utils.IsNullOrEmptyObject(currentComp)
    ) {
      return;
    }
    var itemComp = { ...currentComp };
    serviceItem.DiscountTotal = serviceItem.DiscountTotal - itemComp.discount;
    var serviceItemTotalPrice = serviceItem.TotalPrice;
    var serviceItemDiscountTotal = serviceItem.DiscountTotal;
    var currentDiscountTotal =
      itemComp.type === OfferTypes.PercentDiscount
        ? ((serviceItemTotalPrice - serviceItemDiscountTotal) *
          itemComp.value) /
        100
        : itemComp.value;
    var discountIndex = serviceItem.Discounts.findIndex(
      (d) => d.CampaignId === itemComp.compId
    );
    serviceItem.Discounts[discountIndex].DiscountTotal = currentDiscountTotal;
    serviceItem.DiscountTotal =
      serviceItem.DiscountTotal + currentDiscountTotal;
    itemComp.discount = currentDiscountTotal;
    setCurrentComp(itemComp);
    onEditComp(itemComp, itemComplimentaryList.indexOf(itemComp));
  };

  const addComp = () => {
    var compDesc = "Item Complimentary";
    var discountType =
      compType === "Discount" ? OfferTypes.PercentDiscount : OfferTypes.Cash;
    var compDescDetailed =
      compType === "Discount"
        ? "Discount Percentage " + compValue + "%"
        : "Cash Back " + Utils.GetCurrency(compValue, countryInfo);
    var compId = Utils.CreateGuid();
    var discountTotal = !Utils.IsNullOrEmptyArray(itemComplimentaryList)
      ? itemComplimentaryList.map((o) => o.discount).reduce((a, b) => a + b)
      : 0;
    var currentDiscountTotal = 0;
    var newCompValue = parseFloat(compValue);
    if (newCompValue > 0) {
      var serviceItemTotalPrice = serviceItem.TotalPrice;
      var serviceItemDiscountTotal = serviceItem.DiscountTotal;
      currentDiscountTotal =
        discountType === OfferTypes.PercentDiscount
          ? ((serviceItemTotalPrice - serviceItemDiscountTotal) *
            newCompValue) /
          100
          : newCompValue;
      currentDiscountTotal = Utils.ConvertToFloat(
        currentDiscountTotal.toFixed(2)
      );
      discountTotal += currentDiscountTotal;
      var discount = {
        CampaignId: compId,
        BenefitId: "",
        CampaignType: "Advertisement",
        OffersClassificationType: "Campaign",
        BenefitType: "DiscountPercentage",
        Code: compDescDetailed,
        Description: compDesc,
        DescriptionDetail: compDescDetailed,
        Quantity: 0.0,
        Complimentary: true,
        OrderLevel: false,
        PointsRedemption: false,
        DiscountType: discountType,
        DiscountValue: newCompValue,
        DiscountTotal: currentDiscountTotal,
      };
      serviceItem.Discounts.push(discount);
      serviceItem.DiscountTotal = serviceItem.Discounts.map(
        (d) => d.DiscountTotal
      ).reduce((a, b) => a + b);
      var totalPrice = serviceItem.TotalPrice - serviceItem.DiscountTotal;
      var totalpriceIncludingTax =
        (totalPrice * (serviceItem.TaxPercent + 100)) / 100;
      var taxamount = totalpriceIncludingTax - totalPrice;
      serviceItem.TaxAmount = taxamount;
      serviceItem.TotalPriceIncludingTax = totalpriceIncludingTax;
      var newComp = {
        orderLevel: false,
        productId: serviceItem.ProductId,
        index: serviceItem.Sequence,
        value: newCompValue,
        type: discountType,
        discount: currentDiscountTotal,
        compId: compId,
      };
      var action = {
        type: serviceItemActionTypes.Edit,
        value: serviceItem,
      };
      handleAction(action);
      handleCloseComplimentary();
      setIsViewDiscountIcon(false);
      onAddComp(newComp);
      setCurrentComp(newComp);
    }
  };

  const removeComp = (index) => {
    var currentItemComp = itemComplimentaryList.find(
      (c) => c.index === serviceItem.Sequence
    );
    onRemoveComp(currentItemComp);
    serviceItem.Discounts = serviceItem.Discounts.filter(
      (d) => d.CampaignId !== currentItemComp.compId
    );
    serviceItem.DiscountTotal = Utils.IsNullOrEmptyArray(serviceItem.Discounts)
      ? 0
      : serviceItem.Discounts.map((d) => d.DiscountTotal).reduce(
        (a, b) => a + b
      );
    var totalPrice = serviceItem.TotalPrice - serviceItem.DiscountTotal;
    var totalpriceIncludingTax =
      (totalPrice * (serviceItem.TaxPercent + 100)) / 100;
    var taxamount = totalpriceIncludingTax - totalPrice;
    serviceItem.TaxAmount = taxamount;
    serviceItem.TotalPriceIncludingTax = totalpriceIncludingTax;
    var action = {
      type: serviceItemActionTypes.Edit,
      value: serviceItem,
    };
    handleAction(action);
    setCurrentComp({});
    setIsViewDiscountIcon(true);
  };

  const onChangeCompValue = (newValue) => {
    if (Utils.IsNullOrEmptyObject(currentComp)) {
      return;
    }
    var tempCurrentComp = { ...currentComp };
    tempCurrentComp.value = Utils.ConvertToFloat(newValue);
    if (
      !Utils.IsNullOrEmptyArray(serviceItem.Discounts) &&
      serviceItem.Discounts.some((d) => d.CampaignId === tempCurrentComp.compId)
    ) {
      var discountIndex = serviceItem.Discounts.findIndex(
        (d) => d.CampaignId === tempCurrentComp.compId
      );
      serviceItem.Discounts[discountIndex].DiscountValue =
        tempCurrentComp.value;
      var compDescDetailed =
        tempCurrentComp.type === OfferTypes.PercentDiscount
          ? "Discount Percentage " + tempCurrentComp.value + "%"
          : "Cash Back " + tempCurrentComp.value;
      serviceItem.Discounts[discountIndex].DescriptionDetail = compDescDetailed;
      serviceItem.DiscountTotal =
        serviceItem.DiscountTotal - tempCurrentComp.discount;
      var serviceItemTotalPrice = serviceItem.TotalPrice;
      var serviceItemDiscountTotal = serviceItem.DiscountTotal;
      var currentDiscountTotal =
        tempCurrentComp.type === OfferTypes.PercentDiscount
          ? ((serviceItemTotalPrice - serviceItemDiscountTotal) *
            tempCurrentComp.value) /
          100
          : tempCurrentComp.value;
      tempCurrentComp.discount = currentDiscountTotal;
      serviceItem.Discounts[discountIndex].DiscountTotal = currentDiscountTotal;
      serviceItem.DiscountTotal =
        serviceItem.DiscountTotal + currentDiscountTotal;
      var totalPrice = serviceItem.TotalPrice - serviceItem.DiscountTotal;
      var totalpriceIncludingTax =
        (totalPrice * (serviceItem.TaxPercent + 100)) / 100;
      var taxamount = totalpriceIncludingTax - totalPrice;
      serviceItem.TaxAmount = taxamount;
      serviceItem.TotalPriceIncludingTax = totalpriceIncludingTax;
      var action = {
        type: serviceItemActionTypes.Edit,
        value: serviceItem,
      };
      handleAction(action);
    }
    onEditComp(tempCurrentComp, itemComplimentaryList.indexOf(tempCurrentComp));
    setCurrentComp(tempCurrentComp);
  };

  const onChangeCompType = (type) => {
    setCompType(type);
  }
  useEffect(() => {
    recalculateDiscountOnPriceOrQuantityChange();
  }, [price]);

  useEffect(() => {
    updateDescription();
  }, [description]);

  const updateDescription = () => {
    serviceItem.Description = description;
  }

  return (
    <>
      {/* Add Comp Modal */}
      <ComplimentaryPopup
        open={showComplimentary}
        compType={compType}
        compTypeSetter={onChangeCompType}
        existItemComp={{ orderLevel: false }
        }
        closeComplimentary={handleCloseComplimentary}
        countryInfo={countryInfo}
        addComp={addComp}
        compValue={compValue}
        removeCompItem={removeComp}
        compValueSetter={setCompValue}
        maxLimit={maxPrice}
      />
      <Grid flex="1" spacing={2} padding={1} className="card mb-h">
        <Grid container xs={12} padding="0 10px 0 0">
          <Grid xs={2} lg={1} alignSelf="center" className="remove-icon">
            <Tooltip title="Remove" placement="bottom">
              <Button onClick={handleRemove} className="remove-btn">
                <DeleteForeverRoundedIcon style={{ color: "#ff0000" }} />
              </Button>
            </Tooltip>
          </Grid>
          <Grid xs={10} lg={4} alignSelf="center">
            <TextField
              level="h2"
              className="h2 font-size-14"
              component="h4"
              fontWeight="600"
              value={description}
              multiline
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <PriceComponent
            price={price}
            currency={countryInfo.Currency}
            handleActions={handlePrice}
          />
          <QuantityComponent
            quantity={quantity}
            handleActions={handleQuantity}
          />
          <Grid
            xs={4}
            lg={1}
            justifyContent="Center"
            style={{ display: "flex" }}
          >
            {isDiscount && Utils.ConvertToFloat(price) > 0 && (
              <Grid>
                {isViewDiscountIcon && (
                  <label
                    className="btn"
                    title="Add Discount"
                    style={{ "align-self": "center" }}
                    onClick={() => setShowComplimentary(true)}
                  >
                    <CardGiftcardIcon
                      style={{ "font-size": "24px", color: "#9c27b0" }}
                    />
                  </label>
                )}

                {!isViewDiscountIcon &&
                  !Utils.IsNullOrEmptyObject(currentComp) && (
                    <Stack direction="row">
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                        className="discount-label"
                      >
                        {" "}
                        <FormControl
                          fullWidth
                          style={{ width: "30px" }}
                          className="text-center"
                        >
                          {currentComp.type === OfferTypes.PercentDiscount ? (
                            <label style={{ margin: "0" }}>%</label>
                          ) : (
                            <label style={{ margin: "0" }}>
                              {getSymbolFromCurrency(countryInfo.Currency)}
                            </label>
                          )}
                        </FormControl>
                        <CompValueEntryField
                          label=""
                          type="text"
                          alignSelf="center"
                          variant="outlined"
                          value={currentComp.value}
                          setValue={onChangeCompValue}
                          maxLimit={maxPrice}
                          compType={currentComp.type}
                          className="inputField text-right"
                          style={{ width: "60px", margin: 0 }}
                        />
                      </Stack>
                      <CloseIcon
                        style={{ padding: '13px 5px' }}
                        onClick={() => removeComp(0)}
                        className="btn btn-default discount-remove-btn"
                      />
                    </Stack>
                  )}
              </Grid>
            )}
          </Grid>

          {Utils.ConvertToFloat(price) === 0 && (
            <Grid
              xs={4}
              lg={1}
              justifyContent="Center"
              alignItems="center"
              style={{ display: "flex" }}
            >
              <Typography>0</Typography>
            </Grid>
          )}
          <Grid
            xs={12}
            lg={1}
            className="text-center mt-xs-1"
            alignSelf="center"
          >
            <Stack direction="row" justifyContent="end">
              <small className="visible-xs">Tax Percent : &nbsp;</small>
              <Typography
                level="h4"
                component="h4"
                fontWeight="600"
                fontSize="14px"
              >
                {Utils.IsNullOrEmpty(serviceItem.TaxPercent) || !taxIncluded
                  ? 0 + "%"
                  : serviceItem.TaxPercent.toFixed(2) + "%"}
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12} lg={1} className="text-right" alignSelf="center">
            <Stack direction="row" justifyContent="end">
              <small className="visible-xs">Total Price : &nbsp;</small>
              <Typography
                level="h4"
                component="h4"
                fontWeight="600"
                fontSize="14px"
                sx={{
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  overflowWrap: "break-word"
                }}
              >
                {Utils.GetCurrency(taxIncluded ?
                  serviceItem.TotalPriceIncludingTax : (serviceItem.TotalPriceIncludingTax - serviceItem.TaxAmount),
                  countryInfo,
                  2
                )}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        {isDiscount && Utils.ConvertToFloat(price) > 0 &&
          !Utils.IsNullOrEmptyArray(serviceItem.Discounts) &&
          !Utils.IsNullOrEmptyArray(
            serviceItem.Discounts.filter(
              (d) =>
                !d.OrderLevel &&
                d.Complimentary &&
                itemComplimentaryList
                  .map((i) => i.compId)
                  .includes(d.CampaignId)
            )
          ) &&
          serviceItem.Discounts.filter(
            (d) =>
              !d.OrderLevel &&
              d.Complimentary &&
              itemComplimentaryList.map((i) => i.compId).includes(d.CampaignId)
          ).map((discount) => (
            <ItemDiscountComponent discount={discount}
              countryInfo={countryInfo} />
          ))}
      </Grid>
    </>
  );
};
export default ServiceItem;
