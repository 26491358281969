import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { postAsync } from "../../core/serviceClient";
import "../../Style.css";
import "../../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import Utils from "../../core/Utils";
import { useDispatch } from "react-redux";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Loader from "../common/Loader";
import {
  Container,
  Stack,
  Grid,
  Typography,
  Button,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Chip,
} from "@mui/material";
import MessagePopup from "../common/MessagePopUp";
import SideBarMenuItems from "../SideBarMenuItems";
import UserHelper from "../../core/UserHelper";
import {
  LoginPage,
  PaymentLinksPage,
  PaymentsPage,
} from "../../core/PageConstants";
import { connectToStore } from "../../data/store";
import {
  MaxDateLimit,
  PaymentMethodTypes,
  RazorpayPaymentLinkStatuses,
} from "../../core/Constants";
import AmountTypography from "../common/controls/AmountTypography";
import CountryHelper from "../../core/CountryHelper";
import { getCountry } from "../../data/localSettingsActions";

const All = "ALL";

const PaymentLinks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //Loader hook
  const [isLoading, setIsLoading] = useState(false);
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);

  const [localSettings, setLocalSettings] = useState({});
  const [branch, setBranch] = useState({});
  const [business, setBusiness] = useState({});
  const [countryInfo, setCountryInfo] = useState({});

  //Alert Hooks
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [paid, setPaid] = useState(0);
  const [requested, setRequested] = useState(0);
  const [refunded, setRefunded] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const [allPaymentLinkRecords, setAllPaymentLinkRecords] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = async () => {
    var _localSetting = connectToStore();
    if (UserHelper.CheckPermission(PaymentLinksPage, navigate, _localSetting)) {
      setLocalSettings(_localSetting);
      var _business = _localSetting.business;
      var _branch = _localSetting.branch;
      var _country = _localSetting.country;
      setCountryInfo(_country);
      setBusiness(_business);
      setBranch(_branch);
      if (!UserHelper.IsStateValid(location.state, navigate))
        return;

      var startDT = Utils.ConvertToDate(location.state.startDT);
      var endDT = Utils.ConvertToDate(location.state.endDT);
      setSelectedDate([startDT, endDT]);
      var request = {
        BranchId: _branch.branchId,
        BusinessId: _business.Id,
        StartDT: Utils.GetISODateTimeString(startDT),
        EndDT: Utils.GetISODateTimeString(endDT),
      };
      var result = await getRecords(request);
      if (result.error) {
        setIsNavigateToLogin(result.isNavigateToLogin);
        setAlert(result.errorMessage);
        return;
      }
      setAllPaymentLinkRecords(result.data.paymentLinks);
      filterRecords(All, result.data.paymentLinks, true);
      setSelectedStatus(All);
    }
  };

  const getRecords = async (request) => {
    setIsLoading(true);
    var result = await postAsync("PaymentIN/GetPaymentLinksData", request);
    setIsLoading(false);
    return result;
  };

  const onDateChange = async (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setAlert(
          "Please select a valid date range."
        );
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setAlert(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );
        } else {
          setSelectedDate(dateArray);
          var request = {
            BranchId: branch.branchId,
            BusinessId: business.Id,
            StartDT: Utils.GetISODateTimeString(dateArray[0]),
            EndDT: Utils.GetISODateTimeString(dateArray[1]),
          };
          var result = await getRecords(request);
          if (result.error) {
            setIsNavigateToLogin(result.isNavigateToLogin);
            setAlert(result.errorMessage);
            return;
          }
          setAllPaymentLinkRecords(result.data.paymentLinks);
          filterRecords(selectedStatus, result.data.paymentLinks, true);
        }
      }
    }
  };

  useEffect(() => {
    populateStatuses();
  }, [selectedStatus, allPaymentLinkRecords]);

  const populateStatuses = () => {
    if (Utils.IsNullOrEmptyArray(allPaymentLinkRecords)) {
      setStatuses([]);
      return;
    }
    var _statuses = allPaymentLinkRecords.map((a) => a.status.toUpperCase());
    _statuses = Utils.RemoveDuplicates(_statuses);
    if (selectedStatus !== All) {
      _statuses = [All].concat(_statuses);
    }
    setStatuses(_statuses);
  };

  const filterRecords = (status, arr = [], sort) => {
    if (Utils.IsNullOrEmptyArray(arr)) {
      arr = allPaymentLinkRecords;
    }
    if (Utils.IsNullOrEmptyArray(arr)) {
      setFilteredRecords([]);
      return;
    }
    var sorted = [];
    switch (status) {
      case All:
        setSelectedStatus(All);
        sorted = arr;
        break;
      default:
        setSelectedStatus(status);
        sorted = arr.filter((a) => a.status.toUpperCase() === status);
        break;
    }
    if (sort) {
      sorted = sorted.sort((a, b) => a.createdDT - b.createdDT).reverse();
    }
    setFilteredRecords(sorted);
  };

  useEffect(() => {
    populateSummary();
  }, [filteredRecords]);

  const populateSummary = () => {
    if (Utils.IsNullOrEmptyArray(filteredRecords)) {
      setPaid(0);
      setRefunded(0);
      setRequested(0);
      setCancelled(0);
      return;
    }
    var paidLinks = filteredRecords.filter(
      (f) => f.status === RazorpayPaymentLinkStatuses.Captured
    );
    var _paid = !Utils.IsNullOrEmptyArray(paidLinks)
      ? paidLinks.map((f) => f.amount).reduce((a, b) => a + b)
      : 0;
    var refundedLinks = filteredRecords.filter((f) => f.refundAmount > 0);
    var _refunded = !Utils.IsNullOrEmptyArray(refundedLinks)
      ? refundedLinks.map((r) => r.refundAmount).reduce((a, b) => a + b)
      : 0;
    var cancelledLinks = filteredRecords.filter(
      (f) => f.status === RazorpayPaymentLinkStatuses.Cancelled
    );
    var _cancelled = !Utils.IsNullOrEmptyArray(cancelledLinks)
      ? cancelledLinks.map((c) => c.amount).reduce((a, b) => a + b)
      : 0;
    var requestedLinks = filteredRecords.filter(
      (f) => f.status === RazorpayPaymentLinkStatuses.Created
    );
    var _requested = !Utils.IsNullOrEmptyArray(requestedLinks)
      ? requestedLinks.map((c) => c.amount).reduce((a, b) => a + b)
      : 0;
    setPaid(_paid);
    setRefunded(_refunded);
    setRequested(_requested);
    setCancelled(_cancelled);
  };

  const setAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const backNavigation = () => {
    if (!UserHelper.IsStateValid(location.state, navigate))
      return;

    navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
      state: {
        ...location.state,
        navigationStack: Utils.RemoveLastElementFromArray(
          location.state.navigationStack
        ),
      },
    });
  };

  const openLink = (url) => {
    window.open(encodeURI(url), "_blank");
  };

  const onSelect = (paymentLink, event) => {
    event.stopPropagation();
    var newState = {
      ...location.state,
      visit: { id: paymentLink.visitId, businessId: paymentLink.businessId },
      navigationStack: Utils.AddElementToArray(
        location.state.navigationStack,
        PaymentLinksPage.Path
      ),
    };
    navigate(PaymentsPage.Path, { state: newState });
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      {/* Loader */}
      <Loader open={isLoading} />

      {/* Message Popup */}
      <MessagePopup
        msgOpen={showAlert}
        msgText={alertMessage}
        onMsgClose={handleAlertClose}
      />

      {/* Main */}
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={location.state.activeMenu} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <Grid flex="1" spacing={2} padding="20px">
                {/*Title*/}
                <Grid
                  container
                  className="title-sec"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    <Button
                      onClick={() => backNavigation()}
                      className="back-btn"
                    >
                      <NavigateBeforeIcon />
                    </Button>
                    Payment Links
                  </Typography>
                  {/*Date Picker*/}
                  <Grid className="page-title text-center text-right">
                    <DateRangePicker
                      showLeadingZeros={true}
                      format="dd/MM/yyyy"
                      onChange={onDateChange}
                      maxDate={new Date()}
                      value={selectedDate}
                      locale={CountryHelper.GetDateCulture(getCountry().Culture)}
                      clearIcon={null}
                      calendarIcon={
                        <CalendarMonthOutlinedIcon
                          style={{
                            "font-size": "24px",
                            "align-self": "center",
                            color: "#666666",
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container xs={12} className="p-lg-2" direction="column">
                  {/* Statuses */}
                  {!Utils.IsNullOrEmptyArray(statuses) && (
                    <Grid
                      direction="row"
                      alignItems="baseline"
                      justifyContent="flex-start"
                      spacing={2}
                      marginBottom="10px"
                      className="font-size-13"
                    >
                      {statuses.map((status) => (
                        <Chip
                          component={Paper}
                          onClick={() => filterRecords(status)}
                          label={status}
                          color="primary"
                          variant={selectedStatus === status ? "" : "outlined"}
                        />
                      ))}
                    </Grid>
                  )}
                  {/* Display Table */}
                  <TableContainer component={Paper} className="visit-table">
                    <Table stickyHeader area-lang="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Amount</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell style={{ width: "200px" }}>Link</TableCell>
                          <TableCell>Date & Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredRecords.map((row, index) => (
                          <TableRow onClick={(e) => onSelect(row, e)}>
                            <TableCell
                              data-status={row.status}
                              className="visit-card"
                            >
                              <AmountTypography
                                value={row.amount}
                                country={countryInfo}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography
                                component="p"
                                className="m-0"
                                fontWeight="800"
                              >
                                {row.status.toUpperCase()}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ width: "200px" }}>
                              <Link
                                underline="always"
                                component="button"
                                variant="body2"
                                onClick={() => openLink(row.paymentLinkUrl)}
                              >
                                {row.paymentLinkUrl}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {Utils.ConvertToLocalDT(
                                row.captured
                                  ? row.capturedDT
                                  : row.refunded
                                    ? row.refundDT
                                    : row.createdDT,
                                countryInfo
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* Summary */}
                  <Grid
                    className="font-size-13"
                    direction="column"
                    marginTop="10px"
                    padding="10px"
                    component={Paper}
                  >
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography>Paid</Typography>
                      <AmountTypography value={paid} country={countryInfo} />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography>Requested</Typography>
                      <AmountTypography
                        value={requested}
                        country={countryInfo}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography>Refunded</Typography>
                      <AmountTypography
                        value={refunded}
                        country={countryInfo}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography>
                        <strong>Cancelled</strong>
                      </Typography>
                      <AmountTypography
                        value={cancelled}
                        country={countryInfo}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default PaymentLinks;
