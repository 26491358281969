import React, { useState, useEffect } from "react";
import "../Style.css";
import {
  styled,
  IconButton,
  TextField,
  Button,
  Container,
  Box,
  Grid,
  Stack,
  Typography,
  FormControlLabel,
  FormControl,
  FormGroup,
  Radio,
  RadioGroup,
  Checkbox,
  Switch,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Tooltip,
  DialogActions,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connectToStore } from "../data/store";
import "../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import Utils from "../core/Utils";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import SideBarMenuItems from "./SideBarMenuItems";
import {
  VisitStatuses,
  VisitTypes,
  PaymentMethodTypes,
  SideBarMenuNames,
  ConfirmTypes,
  VisitTypeEnum,
  MaxDateLimit,
  USA,
  AlertSMSNotSupported,
} from "../core/Constants";
import PaymentsIcon from "@mui/icons-material/Payments";
import UserHelper from "../core/UserHelper";
import { postAsync } from "../core/serviceClient";

// Images
import CloseIcon from "@mui/icons-material/Close";
import Success_Icon from "../images/success.gif";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import CallIcon from "@mui/icons-material/Call";
import CountryHelper from "../core/CountryHelper";
import BusinessHelper from "../core/BusinessHelper";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DescriptionIcon from "@mui/icons-material/Description";
import ReplyIcon from "@mui/icons-material/Reply";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Checkout_Icon from "../images/Checkout.png";
import Jobcard_Icon from "../images/Jobcard.png";
import NumericTextField from "./common/controls/NumericTextField";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// Menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import {
  CheckoutDetailsPage,
  OverviewPage,
  DeliveredJobCardDetailsPage,
  JobCardDetailsPage,
  LoginPage,
  VisitsPage,
  PaymentsPage,
} from "../core/PageConstants";
import { SuccessDialog } from "./common/SuccessDialog";
import { CanRaisePaymentLink } from "../core/ModuleHelper";
import { getCountry } from "../data/localSettingsActions";

const getMobileNumber = (visit) => {
  let mobileNumber = visit.isBusiness
    ? visit.receiptMobileNumber
    : visit.customerMobileNumber;
  return mobileNumber;
};

const getMaskMobileNumber = (visit, user) => {
  let mask =
    !Utils.IsNullOrEmptyObject(user) &&
    user.maskCustomerDetails;
  let mobileNumber = getMobileNumber(visit);
  if (mask) {
    if (!Utils.IsNullOrEmpty(mobileNumber))
      mobileNumber = Utils.MaskMobileNumber(mobileNumber);
  }
  return mobileNumber;
};

export const getMaskedMobileCell = (visit, user) => {
  var mobileNumber = getMaskMobileNumber(visit, user);
  if (!Utils.IsNullOrEmpty(mobileNumber))
    return (
      <Typography component="p" className="m-0">
        <CallIcon /> {mobileNumber}
      </Typography>
    );
};
const getEmailId = (visit) => {
  var emailId = visit.isBusiness
    ? visit.receiptEmailId
    : visit.customerEmailId;
  return emailId;
};
export const getEmailCell = (visit) => {
  var email = getEmailId(visit);
  if (!Utils.IsNullOrEmpty(email))
    return (
      <Typography
        component="p"
        className="text-ellipsis"
        style={{ width: "200px" }}
      >
        <EmailIcon />
        {email}
      </Typography>
    );
};

const getCustomerName = (visit) => {
  let customerName = visit.isBusiness
    ? visit.receiptCustomerName
    : visit.customerName;
  return customerName;
};

export const getCustomerCell = (visit) => {
  var customer = getCustomerName(visit);
  if (!Utils.IsNullOrEmpty(customer))
    return (
      <Typography component="p" className="m-0" fontWeight="600">
        <span>
          <PersonIcon /> {customer}
        </span>
      </Typography>
    );
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": { width: "70%" },
}));

const Visits = (props) => {
  document.title = "Visits";
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [localSettings, setLocalSettings] = useState({});
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  let selectedCustomerParam = {},
    startDTParam = Utils.GetStartDate(moment()),
    endDTParam = Utils.GetEndDate(moment()),
    visitStatusParam = "",
    paymentTypeParam = "",
    statePaymentTypes = [],
    searchTxt = "",
    stateCategory = "",
    stateProfitVisible = false,
    stateWithoutDateRange = false,
    selectedPayments = [],
    paymentObj = [],
    visitTypeList = [],
    selectedVisitType = [],
    stateUnPaid = false;
  if (!Utils.IsNullOrEmptyObject(location.state)) {
    if (!Utils.IsNullOrEmptyObject(location.state.selectedCustomer)) {
      selectedCustomerParam = location.state.selectedCustomer;
      startDTParam = Utils.GetDefaultDate();
      endDTParam = Utils.GetDefaultDate();
    }
    if (!Utils.IsNullOrEmpty(location.state.startDT)) {
      startDTParam = Utils.ConvertToDate(location.state.startDT);
    }
    if (!Utils.IsNullOrEmpty(location.state.endDT)) {
      endDTParam = Utils.ConvertToDate(location.state.endDT);
    }
  }

  const [selectedCustomer, setSelectedCustomer] = useState(
    selectedCustomerParam
  );
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [isBusinessCountryUAE, setIsBusinessCountryUAE] = useState(false);
  const [searchText, setSearchText] = useState(searchTxt);
  const [IsFromCustomerpage, setIsFromCustomerpage] = useState(false);
  const [visitStatus, setVisitStatus] = useState(visitStatusParam);
  const [paymentType, setPaymentType] = useState(paymentTypeParam);
  const [paymentTypes, setPaymentTypes] = useState(paymentObj);
  const [openResendInvoice, setOpenResendInvoice] = useState(false);
  const [visitorsList, setVisitorsList] = useState([]);
  const [tempVisitorsList, setTempVisitorsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(VisitTypes.All);
  const [selectedPaymentTypes, setSelectedPaymentTypes] =
    useState(selectedPayments);
  const [isProfitVisible, setIsProfitVisible] = useState(stateProfitVisible);
  const [voidPassId, setVoidPassId] = useState([]);
  const [resendInvoice, setResendInvoice] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [jobcardStatusVal, setJobcardStatusVal] = useState("");
  const [openJobcardStatus, setOpenJobcardStatus] = useState(false);
  const [selectedJobcard, setSelectedJobcard] = useState({});
  const [isUnPaid, setIsUnPaid] = useState(
    stateUnPaid || paymentTypeParam === PaymentMethodTypes.UnPaid
  );
  const [partiallyPaid, setPartiallyPaid] = useState(false);
  const [isSendNotification, setIsSendNotification] = useState(false);
  const [sendNotificationLabelText, setSendNotificationLabelText] =
    useState("");
  const [openRefund, setOpenRefund] = useState(false);
  const [refundReason, setRefundReason] = useState("");
  const [refundServiceItems, setRefundServiceItems] = useState([]);
  const [refundInvoice, setRefundInvoice] = useState({});
  const [refundSpend, setRefundSpend] = useState("");
  const [odometerOut, setOdometerOut] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [confirmFor, setConfirmFor] = useState("");
  const [withoutDateRange, setWithoutDateRange] = useState(
    stateWithoutDateRange
  );
  const [selectedRefundItems, setSelectedRefundItems] = useState([]);
  const [navigationStack, setNavigationStack] = useState([]);
  const [onSuccess, setOnSuccess] = useState("");
  const [isBackNavigationEnabled, setIsBackNavigationEnabled] = useState(false);
  let jobStatusList = [
    {
      value: VisitStatuses.Open,
      text: "Open",
    },
    {
      value: VisitStatuses.Assigned,
      text: "Assigned",
    },
    {
      value: VisitStatuses.WorkInProgress,
      text: "Work In Progress",
    },
    {
      value: VisitStatuses.Completed,
      text: "Completed",
    },
  ];
  const [jobcardStatusList, setJobcardStatusList] = useState(jobStatusList);

  useEffect(() => {
    const select = [startDTParam, endDTParam];
    let localSetting = connectToStore();
    if (!localSetting.authenticated) {
      navigate(LoginPage.Path);
    } else {
      var isStateEmpty = Utils.IsNullOrEmptyObject(location.state);
      var isNavigationStackEmpty =
        isStateEmpty ||
        Utils.IsNullOrEmptyArray(location.state.navigationStack);
      setNavigationStack(
        isNavigationStackEmpty ? [] : location.state.navigationStack
      );
      setOnSuccess(
        isNavigationStackEmpty ||
          location.state.navigationStack[0] === OverviewPage.Path
          ? VisitsPage.Path
          : location.state.navigationStack[0]
      );
      setIsBackNavigationEnabled(!isNavigationStackEmpty);
      setPartiallyPaid(
        !Utils.IsNullOrEmptyObject(location.state) &&
          !Utils.IsNullOrEmpty(location.state.partiallyPaid)
          ? location.state.partiallyPaid
          : false
      );
      setLocalSettings(localSetting);
      if (!Utils.IsNullOrEmptyObject(location.state)) {
        if (!Utils.IsNullOrEmpty(location.state.visitStatus)) {
          visitStatusParam = location.state.visitStatus;
        }
        if (!Utils.IsNullOrEmpty(location.state.paymentType)) {
          paymentTypeParam = location.state.paymentType;
        }
        if (!Utils.IsNullOrEmpty(location.state.searchText)) {
          searchTxt = location.state.searchText;
        }
        if (!Utils.IsNullOrEmpty(location.state.selectedCategory)) {
          stateCategory = location.state.selectedCategory;
        }
        if (!Utils.IsNullOrEmptyArray(location.state.selectedPaymentTypes)) {
          statePaymentTypes = location.state.selectedPaymentTypes;
        }
        if (!Utils.IsNullOrEmpty(location.state.isProfitVisible)) {
          stateProfitVisible = location.state.isProfitVisible;
        }
        if (!Utils.IsNullOrEmpty(location.state.isUnPaid)) {
          stateUnPaid = location.state.isUnPaid;
        }
        if (!Utils.IsNullOrEmpty(location.state.withoutDateRange)) {
          stateWithoutDateRange = location.state.withoutDateRange;
        }
      }
      setSearchText(searchTxt);
      setIsFromCustomerpage(location.state.isfromCustomer);
      setSelectedDate(select);
      setVisitStatus(visitStatusParam);
      setPaymentType(paymentTypeParam);
      visitTypeList = [
        {
          title: VisitTypes.All,
          visitType: VisitTypes.All,
          isChecked: Utils.IsNullOrEmpty(visitStatusParam),
        },
        {
          title: "Job Cards",
          visitType: VisitTypes.JobCard,
          isChecked: visitStatusParam === VisitTypes.JobCard,
        },
        {
          title: "Check-Outs",
          visitType: VisitTypes.CheckOut,
          isChecked: visitStatusParam === VisitTypes.CheckOut,
        },
        {
          title: VisitTypes.Delayed,
          visitType: VisitTypes.Delayed,
          isChecked: visitStatusParam === VisitTypes.Delayed,
        },
      ];
      selectedVisitType = visitTypeList.filter((item) => item.isChecked);
      paymentObj = [
        {
          text: "UPI",
          value: PaymentMethodTypes.UPI,
          isChecked:
            paymentTypeParam === PaymentMethodTypes.UPI ||
            statePaymentTypes.some((item) => item === PaymentMethodTypes.UPI),
          isVisible: CountryHelper.IsOtherPaymentsAvailable(
            localSettings.country
          ),
        },
        {
          text: "Cash",
          value: PaymentMethodTypes.Cash,
          isChecked:
            paymentTypeParam === PaymentMethodTypes.Cash ||
            statePaymentTypes.some((item) => item === PaymentMethodTypes.Cash),
          isVisible: true,
        },
        {
          text: "Card",
          value: PaymentMethodTypes.Card,
          isChecked:
            paymentTypeParam === PaymentMethodTypes.Card ||
            statePaymentTypes.some((item) => item === PaymentMethodTypes.Card),
          isVisible: true,
        },
        {
          text: "Net Banking",
          value: PaymentMethodTypes.NetBanking,
          isChecked:
            paymentTypeParam === PaymentMethodTypes.NetBanking ||
            statePaymentTypes.some(
              (item) => item === PaymentMethodTypes.NetBanking
            ),
          isVisible: CountryHelper.IsOtherPaymentsAvailable(
            localSettings.country
          ),
        },
        {
          text: "Payment Link",
          value: PaymentMethodTypes.PaymentLink,
          isChecked:
            paymentTypeParam === PaymentMethodTypes.PaymentLink ||
            statePaymentTypes.some(
              (item) => item === PaymentMethodTypes.PaymentLink
            ),
          isVisible: CanRaisePaymentLink(localSettings.business),
        },
      ];
      let tempPayments = paymentObj.filter(
        (item) => item.isChecked && item.isVisible
      );
      let selectedPayments = [];
      if (!Utils.IsNullOrEmptyArray(tempPayments))
        selectedPayments.push(tempPayments[0].value);
      setPaymentTypes(paymentObj);
      setSelectedCategory(
        !Utils.IsNullOrEmpty(stateCategory)
          ? stateCategory
          : !Utils.IsNullOrEmptyArray(selectedVisitType)
            ? selectedVisitType[0].visitType
            : VisitTypes.All
      );
      setCategoryList(visitTypeList);
      setSelectedPaymentTypes(selectedPayments);
      setIsProfitVisible(stateProfitVisible);
      setIsUnPaid(
        stateUnPaid || paymentTypeParam === PaymentMethodTypes.UnPaid
      );
      setWithoutDateRange(stateWithoutDateRange);
    }
    var countryDetails = getCountry();
    setIsBusinessCountryUAE(CountryHelper.IsBusinessCountryUAE(countryDetails));
  }, []);
  const calculateProfit = (visit) => {
    let profit = 0;
    if (!Utils.IsNullOrEmptyObject(visit)) {
      if (!Utils.IsNullOrEmptyArray(visit.serviceItems))
        visit.serviceItems.forEach((si) => {
          profit += si.totalPrice - si.discountTotal - si.quantity * si.cost;
        });
      if (!Utils.IsNullOrEmpty(visit.fees)) profit -= visit.fees;
    }
    return profit;
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );
        setMsgOpen(true);
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setMsgText(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );

          setMsgOpen(true);
        } else {
          setSelectedCustomer("");
          setSelectedDate(dateArray);
          getVisits(dateArray[0], dateArray[1]);
        }
      }
    }
  };

  async function getVisits(startDate, endDate) {
    if (localSettings.authenticated) {
      setIsLoading(true);
      var request = {
        Parameters: [],
        QueryId: "",
      };
      if (withoutDateRange && Utils.IsNullOrEmpty(searchText)) {
        let response = await postAsync("Customer/SearchVisit", {
          BusinessId: localSettings.business.id,
          SearchText: searchText,
        });
        setIsNavigateToLogin(response.isNavigateToLogin);
        if (response.error) {
          setMsgOpen(true);
          setMsgText(response.errorMessage);
        } else if (response.data) {
          setVisitorsList(response.data);
        }
      } else {
        if (!Utils.IsNullOrEmptyObject(selectedCustomer)) {
          request.QueryId = "AllVisitsForCustomer";
          request.Parameters = [
            localSettings.business.id,
            localSettings.branch.branchId,
            selectedCustomer.customerId,
          ];
        } else if (visitStatus === VisitTypes.MyJobCard) {
          request.QueryId = "JobcardsByTechnician";
          request.Parameters = [
            localSettings.business.id,
            localSettings.branch.branchId,
            localSettings.user.id,
          ];
        } else if (
          !withoutDateRange &&
          !Utils.IsNullOrEmptyObject(selectedDate[0]) &&
          !Utils.IsNullOrEmptyObject(selectedDate[1])
        ) {
          request.QueryId = "AllVisitsForPeriod";
          request.Parameters = [
            localSettings.business.id,
            localSettings.branch.branchId,
            Utils.GetISODateTimeString(selectedDate[0]),
            Utils.GetISODateTimeString(selectedDate[1]),
          ];
        } else {
          request.QueryId = "AllVisitsForBusiness";
          request.Parameters = [
            localSettings.business.id,
            localSettings.branch.branchId,
            "",
          ];
        }
        if (
          !Utils.IsNullOrEmpty(request.QueryId) &&
          !Utils.IsNullOrEmptyArray(request.Parameters)
        ) {
          let response = await postAsync(
            "StorageClient/GetCustomerVisits",
            request
          );
          setIsNavigateToLogin(response.isNavigateToLogin);
          if (response.error) {
            setMsgOpen(true);
            setMsgText(response.errorMessage);
          } else if (response.data) {
            var resultData = response.data;
            if (
              !Utils.IsNullOrEmpty(searchText) &&
              IsFromCustomerpage === false
            ) {
              var upperCaseSearch = Utils.ConvertToUpperCase(searchText);
              resultData = response.data.filter((v) =>
                filterbyVisits(v, upperCaseSearch)
              );
            }
            if (!Utils.IsNullOrEmpty(visitStatus)) {
              var tempList = [];
              if (
                visitStatus === VisitTypes.Delayed ||
                visitStatus === VisitTypes.MyJobCard
              )
                tempList = resultData;
              else
                tempList = resultData.filter(
                  (v) =>
                    v.visitType === VisitTypeEnum.Jobcard &&
                    v.status === visitStatus
                );
              setVisitorsList(tempList);
            } else setVisitorsList(resultData);
          }
        }
      }
      setIsLoading(false);
    }
  }

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  // Unpaid Filter
  const handleUnpaidFilterItems = (e) => {
    setIsUnPaid(e.target.checked);
  };

  // Partially Paid Filter
  const handlePartiallyPaidFilterItems = (e) => {
    setPartiallyPaid(e.target.checked);
  };

  const filterByCategory = () => {
    var tempVisits = [...visitorsList];
    if (Utils.IsNullOrEmptyArray(tempVisits)) return;
    if (isUnPaid || (isUnPaid && partiallyPaid))
      tempVisits = tempVisits.filter((v) => !v.paid);
    if (!isUnPaid && partiallyPaid)
      tempVisits = tempVisits.filter(
        (v) => v.status === VisitStatuses.PartiallyPaid
      );
    if (selectedCategory === VisitTypes.All) {
      return tempVisits;
    } else if (selectedCategory === VisitTypes.Delayed) {
      return tempVisits.filter(
        (v) =>
          (v.status === VisitStatuses.Open ||
            v.status === VisitStatuses.Assigned ||
            v.status === VisitStatuses.WorkInProgress) &&
          Utils.GetUTC() > v.vehicleServiceDetails.promisedDateTime
      );
    } else return tempVisits.filter((v) => v.visitType === selectedCategory);
  };

  const handleVisitTypeOnChange = (item) => {
    setSelectedCategory(item);
  };

  // Payment Type Filter
  async function filterByPaymentType() {
    var visits = [];
    var filteredVisits = filterByCategory();
    if (!Utils.IsNullOrEmptyArray(filteredVisits)) {
      if (!Utils.IsNullOrEmptyArray(selectedPaymentTypes)) {
        selectedPaymentTypes.forEach((item) => {
          if (item === PaymentMethodTypes.Cash) {
            var tempVisits = filteredVisits.filter(
              (v) =>
                v.cashPayment > 0 &&
                v.paid &&
                v.upiPayment == 0 &&
                v.cardPayment == 0 &&
                v.netBankingPayment == 0
            );
            visits.push.apply(visits, tempVisits);
          }
          if (item === PaymentMethodTypes.UPI) {
            var tempVisits = filteredVisits.filter(
              (v) =>
                v.cashPayment == 0 &&
                v.paid &&
                v.upiPayment > 0 &&
                v.cardPayment == 0 &&
                v.netBankingPayment == 0
            );
            visits.push.apply(visits, tempVisits);
          }
          if (item === PaymentMethodTypes.Card) {
            var tempVisits = filteredVisits.filter(
              (v) =>
                v.cashPayment == 0 &&
                v.paid &&
                v.upiPayment == 0 &&
                v.cardPayment > 0 &&
                v.netBankingPayment == 0
            );
            visits.push.apply(visits, tempVisits);
          }
          if (item === PaymentMethodTypes.NetBanking) {
            var tempVisits = filteredVisits.filter(
              (v) =>
                v.cashPayment == 0 &&
                v.paid &&
                v.upiPayment == 0 &&
                v.cardPayment == 0 &&
                v.netBankingPayment > 0
            );
            visits.push.apply(visits, tempVisits);
          }
        });
      } else {
        visits = filteredVisits.filter(
          (c) =>
            (true || c.paidAmount < c.paymentAmount) &&
            (!Utils.IsDefaultDate(c.checkOut) ||
              !Utils.IsDefaultDate(c.reservationCanceledDT))
        );
      }
    }
    setVisits(visits);
  }

  const setVisits = (visits) => {
    if (BusinessHelper.IsAutomobile(localSettings.business))
      setTempVisitorsList(
        visits.sort((a, b) => (a.jobcardNumber > b.jobcardNumber ? -1 : 1))
      );
    else {
      setTempVisitorsList(
        visits.sort((a, b) => (a.invoiceNumber > b.invoiceNumber ? -1 : 1))
      );
    }
  };
  // Category Filter
  async function handleCategoryFilterOnChange(event, index) {
    var checked = event.target.checked;
    var tempPaymentType = [...paymentTypes];
    tempPaymentType[index].isChecked = checked;
    var categoryFilter = tempPaymentType[index].value;
    setPaymentTypes(tempPaymentType);
    var tempSelectedPayments = [...selectedPaymentTypes];
    if (checked) tempSelectedPayments.push(categoryFilter);
    else {
      tempSelectedPayments = tempSelectedPayments.filter(
        (item) => item !== categoryFilter
      );
    }
    setSelectedPaymentTypes([...tempSelectedPayments]);
  }

  async function passVisitorObj(visitorsObj, VisitDesc, event) {
    event.stopPropagation();
    switch (visitorsObj.visitType) {
      case VisitTypes.CheckOut:
        navigate(CheckoutDetailsPage.Path, {
          state: {
            visit: visitorsObj,
            desc: VisitDesc,
            isProfitVisible: isProfitVisible,
            isUnPaid: isUnPaid,
            navigationStack: Utils.AddElementToArray(
              navigationStack,
              VisitsPage.Path
            ),
            onSuccess: onSuccess,
            withoutDateRange: withoutDateRange,
            selectedCategory: selectedCategory,
            selectedPaymentTypes: selectedPaymentTypes,
            startDT: Utils.GetISODateTimeString(selectedDate[0]),
            endDT: Utils.GetISODateTimeString(selectedDate[1]),
            searchText: searchText,
          },
        });
        break;
      case VisitTypes.JobCard:
        if (
          visitorsObj.status !== VisitStatuses.Delivered &&
          visitorsObj.status !== VisitStatuses.PaymentRefunded
        ) {
          navigate(JobCardDetailsPage.Path, {
            state: {
              checkout: false,
              visit: visitorsObj,
              fromVisit: true,
              fromJobcard: false,
              isProfitVisible: isProfitVisible,
              isUnPaid: isUnPaid,
              navigationStack: Utils.AddElementToArray(
                navigationStack,
                VisitsPage.Path
              ),
              onSuccess: onSuccess,
              withoutDateRange: withoutDateRange,
              selectedCategory: selectedCategory,
              selectedPaymentTypes: selectedPaymentTypes,
              startDT: Utils.GetISODateTimeString(selectedDate[0]),
              endDT: Utils.GetISODateTimeString(selectedDate[1]),
              searchText: searchText,
            },
          });
        } else {
          navigate(DeliveredJobCardDetailsPage.Path, {
            state: {
              visit: visitorsObj,
              desc: VisitDesc,
              isProfitVisible: isProfitVisible,
              isUnPaid: isUnPaid,
              navigationStack: Utils.AddElementToArray(
                navigationStack,
                VisitsPage.Path
              ),
              onSuccess: onSuccess,
              withoutDateRange: withoutDateRange,
              selectedCategory: selectedCategory,
              selectedPaymentTypes: selectedPaymentTypes,
              startDT: Utils.GetISODateTimeString(selectedDate[0]),
              endDT: Utils.GetISODateTimeString(selectedDate[1]),
              searchText: searchText,
            },
          });
        }
        break;
    }
  }

  async function showInvoice(custId, passId, e) {
    e.stopPropagation();
    window.open(
      encodeURI(
        Utils.GetServiceUrl() +
        "home/invoice?culture=" +
        CountryHelper.GetAPICulture(localSettings.country.Culture) +
        "&bid=" +
        localSettings.business.id +
        "&cid=" +
        custId +
        "&vid=" +
        passId +
        "&estimate=False&m=0"
      )
    );
  }

  async function showReceipt(custId, passId, e) {
    e.stopPropagation();
    window.open(
      encodeURI(
        Utils.GetServiceUrl() +
        "home/receipt?culture=" +
        CountryHelper.GetAPICulture(localSettings.country.Culture) +
        "&bid=" +
        localSettings.business.id +
        "&cid=" +
        custId +
        "&vid=" +
        passId +
        "&estimate=False&m=0"
      )
    );
  }

  const handleClickOpenResendInvoice = (row, e) => {
    e.stopPropagation();
    setEmailId(getEmailId(row));
    setMobileNumber(getMaskMobileNumber(row));
    setResendInvoice(row);
    setOpenResendInvoice(true);
  };

  const handleCloseResendInvoice = () => {
    setOpenResendInvoice(false);
  };

  // Send SMS
  async function sendSMSVoice(e) {
    e.preventDefault();
    var tempCountry = localSettings.country;
    if (Utils.IsNullOrEmptyObject(tempCountry)) {
      tempCountry = getCountry();
    }
    if (!tempCountry.SMSEnabled) {
      setMsgOpen(true);
      setMsgText(AlertSMSNotSupported);
      return;
    }
    if (Utils.IsMobileValid(mobileNumber, localSettings.country)) {
      setIsLoading(true);
      let response = await postAsync("Customer/SMSInvoice", {
        BusinessId: localSettings.business.id,
        CompanyName: localSettings.business.companyName,
        CountryCode: localSettings.country.Code,
        CustomerId: resendInvoice.customerId,
        CustomerName: resendInvoice.customerName,
        MobileNumbers: [mobileNumber],
        ServiceUrl: Utils.GetServiceUrl(),
        VisitId: resendInvoice.id,
        SendInvoice: true,
      });
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      } else if (response.data && response.data.success === true) {
        setSuccessMessage("SMS sent successfully.");
        setOpenSuccessModal(true);
      }
      setIsLoading(false);
    } else {
      setMsgOpen(true);
      setMsgText("Please enter valid Mobile.");
    }
  }

  const backNavigation = () => {
    navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
      state: {
        ...location.state,
        navigationStack: Utils.RemoveLastElementFromArray(
          location.state.navigationStack
        ),
      },
    });
  };

  // Send Email
  async function sendEmail(e) {
    e.preventDefault();
    if (Utils.IsEmailValid(emailId)) {
      setIsLoading(true);
      let response = await postAsync(
        CountryHelper.IsBusinessCountryIndia(localSettings.country)
          ? "Customer/EmailInvoice"
          : "Customer/EmailReceipt",
        {
          BusinessId: localSettings.business.id,
          CompanyName: localSettings.business.companyName,
          EmailIds: [emailId],
          ServiceUrl: Utils.GetServiceUrl(),
          VisitIds: [resendInvoice.id],
        }
      );
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      } else if (response.data) {
        if (response.data.success === true) {
          setSuccessMessage("Email sent successfully.");
          setOpenSuccessModal(true);
        }
      }
      setIsLoading(false);
    } else {
      setMsgOpen(true);
      setMsgText("Please enter valid email.");
    }
  }

  async function processVoid() {
    setIsLoading(true);
    let response = await postAsync("Payment/Cancel", {
      BusinessId: localSettings.business.id,
      VisitId: voidPassId,
    });
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
    } else {
      refreshVisits();
    }
    setIsLoading(false);
  }

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (confirmFor == VisitStatuses.Void) processVoid();
      else if (confirmFor == VisitStatuses.PaymentRefunded) processRefund();
    }
    setConfirmFor("");
  };

  const handleVoidClick = (data, event) => {
    event.stopPropagation();
    setVoidPassId(data.id);
    setConfirmFor(VisitStatuses.Void);
    setConfirmText("Are you sure you want to void this visit?");
    setConfirmOpen(true);
  };

  const handleClickPayments = (data, event) => {
    event.stopPropagation();
    var newState = {
      activeMenu: SideBarMenuNames.Visits,
      visit: data,
      isProfitVisible: isProfitVisible,
      isUnPaid: isUnPaid,
      navigationStack: Utils.AddElementToArray(
        navigationStack,
        VisitsPage.Path
      ),
      onSuccess: "",
      withoutDateRange: withoutDateRange,
      selectedCategory: selectedCategory,
      selectedPaymentTypes: selectedPaymentTypes,
      startDT: Utils.GetISODateTimeString(selectedDate[0]),
      endDT: Utils.GetISODateTimeString(selectedDate[1]),
      searchText: searchText,
      payment: {},
    };
    navigate(PaymentsPage.Path, { state: newState });
  };

  // Select Jobcard Status
  const handleJobcardStatusClick = (data, e) => {
    e.stopPropagation();
    setSelectedJobcard(data);
    setJobcardStatusVal(data.status);
    setSendNotificationLabelText(
      "Send Notification to Customer(" + data.customerName + ")"
    );
    setOpenJobcardStatus(true);
  };

  const handleJobcardStatusClose = () => {
    setOpenJobcardStatus(false);
  };

  const submitJobcardStatus = async (e) => {
    e.preventDefault();
    var tempCountry = localSettings.country;
    if (Utils.IsNullOrEmptyObject(tempCountry)) {
      tempCountry = getCountry();
    }
    if (isSendNotification && !tempCountry.SMSEnabled) {
      setMsgOpen(true);
      setMsgText(AlertSMSNotSupported);
      return;
    }
    if (jobcardStatusVal != selectedJobcard.status) {
      if (
        (jobcardStatusVal == VisitStatuses.Assigned ||
          jobcardStatusVal == VisitStatuses.WorkInProgress ||
          jobcardStatusVal == VisitStatuses.Completed) &&
        Utils.IsNullOrEmpty(
          selectedJobcard.vehicleServiceDetails.technicianName
        )
      ) {
        setMsgText("Please assign service advisor to change jobcard status.");
        setMsgOpen(true);
        return;
      } else if (
        localSettings.branch.isOdometerReadingsMandatory &&
        jobcardStatusVal == VisitStatuses.Completed &&
        (Utils.ConvertToInteger(odometerOut) <
          selectedJobcard.vehicleDetails.kmDriven ||
          Utils.IsNullOrEmpty(odometerOut))
      ) {
        if (Utils.IsNullOrEmpty(odometerOut))
          setMsgText("Please enter the Odometer reading out.");
        else
          setMsgText(
            "Odometer reading out should not be lower than odometer reading in."
          );
        setMsgOpen(true);
        return;
      } else if (
        jobcardStatusVal == VisitStatuses.Completed &&
        Utils.IsNullOrEmptyArray(selectedJobcard.serviceItems)
      ) {
        setMsgText("Please add at least 1 service item before proceeding.");
        setMsgOpen(true);
        return;
      } else await processJobcardStatus();
    } else {
      if (
        localSettings.branch.isOdometerReadingsMandatory &&
        jobcardStatusVal == VisitStatuses.Completed &&
        Utils.ConvertToInteger(odometerOut) <
        selectedJobcard.vehicleDetails.kmDriven
      ) {
        setMsgText(
          "Odometer reading out should not be lower than odometer reading in."
        );
        setMsgOpen(true);
        return;
      }
      setOpenJobcardStatus(false);
    }
  };

  async function processJobcardStatus() {
    setIsLoading(true);
    let response = await postAsync("Payment/UpdateVisitStatus", {
      BusinessId: localSettings.business.id,
      VisitId: selectedJobcard.id,
      VisitStatus: jobcardStatusVal,
      SendNotification: isSendNotification,
      KmDrivenOut:
        jobcardStatusVal == VisitStatuses.Completed &&
          localSettings.branch.isOdometerReadingsMandatory
          ? Utils.ConvertToInteger(odometerOut)
          : 0,
    });
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
    } else {
      setOpenJobcardStatus(false);
      refreshVisits();
    }
    setIsLoading(false);
  }

  // Refund
  const handleClickOpenRefund = (data, e) => {
    e.stopPropagation();
    if (data.paidAmount - data.refundAmount > 0) {
      var tempRefunds = data.serviceItems.map((item) => {
        var rQty = item.quantity - item.refundQuantity;
        return {
          ...item,
          refundDiscountTotal: item.DiscountTotal,
          refundTotal:
            item.totalPrice -
            item.refundTotal -
            item.discountTotal +
            (data.taxFree ? 0 : item.taxAmount),
          calculatedRefundTotal:
            item.totalPrice -
            item.refundTotal -
            item.discountTotal +
            (data.taxFree ? 0 : item.taxAmount),
          refundableQuantity: rQty,
          canCheck: rQty > 0,
          isChecked: true,
          isTip: false,
        };
      });
      if (data.tip > 0) {
        var rQty = 1 - item.refundQuantity;
        var item = {
          sequence: -1,
          description: "Tip",
          refundableQuantity: rQty,
          canCheck: rQty > 0,
          calculatedRefundTotal: data.tip,
          isChecked: true,
          isTip: true,
        };

        tempRefunds.push(item);
      }
      setSelectedRefundItems(tempRefunds);
      setRefundServiceItems(tempRefunds);
      setRefundInvoice(data);
      if (!Utils.IsNullOrEmptyArray(tempRefunds)) {
        var rSpend = tempRefunds
          .map((s) => s.calculatedRefundTotal)
          .reduce((a, b) => a + b);
        setRefundSpend(rSpend);
      } else setRefundSpend("0");
      setOpenRefund(true);
    }
  };
  const handleCloseRefund = () => {
    setOpenRefund(false);
  };

  const processRefund = async () => {
    setIsLoading(true);
    let tip = selectedRefundItems.find((item) => item.isTip === true);
    let response = await postAsync("Payment/RefundPayment", {
      Amount: Math.min(
        Utils.ConvertToFloat(refundSpend),
        refundInvoice.paidAmount - refundInvoice.refundAmount
      ),
      BusinessId: localSettings.business.id,
      CompanyName: localSettings.business.companyName,
      Country: localSettings.branch.countryCode,
      PointsEarnRatio: !Utils.IsNullOrEmptyObject(selectedCustomer)
        ? selectedCustomer.pointsEarnRatio
        : 0,
      Reason: refundReason,
      ServiceItems: selectedRefundItems,
      Tip: !Utils.IsNullOrEmptyObject(tip) ? tip.calculatedRefundTotal : 0,
      VisitId: refundInvoice.id,
    });
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
    } else if (response.data) {
      setOpenRefund(false);
      refreshVisits();
    }
    setIsLoading(false);
  };

  const handleCheckboxChange = (index, event) => {
    let checked = event.target.checked;
    var tempRefundServiceItems = [...refundServiceItems];
    var rItem = [...selectedRefundItems];
    tempRefundServiceItems[index].isChecked = checked;
    setRefundServiceItems(tempRefundServiceItems);
    if (checked) {
      tempRefundServiceItems[index].refundDiscountTotal =
        tempRefundServiceItems[index].discountTotal;
      tempRefundServiceItems[index].refundTotal =
        tempRefundServiceItems[index].totalPrice -
        tempRefundServiceItems[index].refundTotal -
        tempRefundServiceItems[index].discountTotal +
        (refundInvoice.taxFree ? 0 : tempRefundServiceItems[index].taxAmount);

      rItem.push(tempRefundServiceItems[index]);
    } else {
      rItem = rItem.filter(
        (item) => item.sequence !== tempRefundServiceItems[index].sequence
      );
    }
    if (!Utils.IsNullOrEmptyArray(rItem)) {
      var rSpend = rItem
        .map((s) => s.calculatedRefundTotal)
        .reduce((a, b) => a + b);
      setRefundSpend(rSpend);
      setSelectedRefundItems(rItem);
    } else {
      setRefundSpend("0");
      setSelectedRefundItems([]);
    }
  };

  async function submitRefundItems(e) {
    e.preventDefault();
    if (Utils.IsNullOrEmpty(refundReason)) {
      setMsgText("Reason required to issue refund.");
      setMsgOpen(true);
      return;
    } else if (Utils.ConvertToInteger(refundSpend) <= 0) {
      setMsgText("Refund amount is invalid.");
      setMsgOpen(true);
      return;
    } else {
      setConfirmFor(VisitStatuses.PaymentRefunded);
      setConfirmText("Are you sure you want to issue this refund?");
      setConfirmOpen(true);
    }
  }

  // Search without date range
  const searchWithoutDateRange = (e) => {
    setWithoutDateRange(e.target.checked);
  };

  // Search
  const handleSearchService = async () => {
    if (!Utils.IsNullOrEmpty(searchText)) {
      let searchVal = Utils.ConvertToUpperCase(searchText);
      let vistorsMatches = [];
      if (withoutDateRange) {
        setIsLoading(true);
        let response = await postAsync("Customer/SearchVisit", {
          BusinessId: localSettings.business.id,
          SearchText: searchText,
        });
        setIsNavigateToLogin(response.isNavigateToLogin);
        if (response.error) {
          setMsgOpen(true);
          setMsgText(response.errorMessage);
        } else if (response.data) {
          vistorsMatches = response.data;
        }
        setIsLoading(false);
      } else {
        vistorsMatches = visitorsList.filter((vList) =>
          filterbyVisits(vList, searchVal)
        );
      }
      setVisits(vistorsMatches);
    }
  };

  const handleKeyUp = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSearchService();
    }
  };

  // Refresh Visits
  const refreshVisits = () => {
    setIsUnPaid(false);
    setPaymentTypes(paymentObj);
    setSelectedPaymentTypes([]);
    getVisits(selectedDate[0], selectedDate[1]);
  };

  // Clear Filter
  const clearFilter = () => {
    setVisitStatus("");
    refreshVisits();
  };
  const handleSendNotificationChange = (event) => {
    setIsSendNotification(event.target.checked);
  };
  useEffect(() => {
    getVisits(selectedDate[0], selectedDate[1]);
  }, [localSettings]);

  useEffect(() => {
    getVisits(selectedDate[0], selectedDate[1]);
  }, [
    localSettings,
    selectedDate[0],
    selectedDate[1],
    paymentType,
    visitStatus,
    withoutDateRange,
  ]);

  useEffect(() => {
    filterByPaymentType();
  }, [
    isUnPaid,
    partiallyPaid,
    selectedCategory,
    selectedPaymentTypes,
    visitorsList,
  ]);

  const filterbyVisits = (item, search) => {
    return (
      Utils.ConvertToUpperCase(item.serviceDesc).includes(search) ||
      Utils.ConvertToUpperCase(item.receiptCustomerName).includes(search) ||
      Utils.ConvertToUpperCase(item.receiptEmailId).includes(search) ||
      Utils.ConvertToUpperCase(item.receiptMobileNumber).includes(search) ||
      Utils.ConvertToUpperCase(item.customerName).includes(search) ||
      Utils.ConvertToUpperCase(item.customerKey).includes(search) ||
      Utils.ConvertToUpperCase(item.customerMobileNumber).includes(search) ||
      Utils.ConvertToUpperCase(item.customerId).includes(search) ||
      Utils.ConvertToUpperCase(item.invoiceNumber).includes(search) ||
      Utils.ConvertToUpperCase(item.jobcardNumber).includes(search) ||
      Utils.ConvertToUpperCase(item.invoiceLabelDescription).includes(search) ||
      Utils.ConvertToUpperCase(item.checkOutUsername).includes(search) ||
      Utils.ConvertToUpperCase(item.checkOutUser).includes(search) ||
      Utils.ConvertToUpperCase(item.checkOutTerminalId).includes(search)
    );
  };

  const populateSortedVisits = () => {
    if (Utils.IsNullOrEmptyArray(tempVisitorsList)) {
      setSortedList([]);
      return;
    }
    var tempVisits = [...tempVisitorsList];
    var result = tempVisits.sort((a, b) => {
      return moment(b.checkOut).diff(moment(a.checkOut));
    });
    if (Utils.IsNullOrEmptyArray(result)) {
      setSortedList([]);
      return;
    }
    setSortedList(result);
  };

  const [sortedList, setSortedList] = useState([]);
  useEffect(() => {
    populateSortedVisits();
  }, [tempVisitorsList]);

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />
      {/* Resend Invoice */}
      <BootstrapDialog
        onClose={handleCloseResendInvoice}
        aria-labelledby="customized-dialog-title"
        open={openResendInvoice}
        className=""
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseResendInvoice}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Resend Invoice
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className="font-size-14">
          <Stack
            direction="row"
            spacing={2}
            className="justify-content-between cs-form text-left p-0"
          >
            <FormControl fullWidth>
              <TextField
                type="email"
                label="Email"
                variant="outlined"
                required
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
              />
            </FormControl>
            <Button
              className="btn btn-sm align-stretch"
              variant="outlined"
              style={{ padding: "2px 8px" }}
              onClick={sendEmail}
            >
              <EmailIcon className="font-size-20" />
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            className="justify-content-between mt-1 cs-form text-left p-0"
          >
            <FormControl fullWidth>
              <NumericTextField
                id="mobileNumber"
                label="Mobile"
                type="tel"
                variant="outlined"
                required
                inputProps={{
                  maxLength: CountryHelper.GetMobileNumberMaxLength(),
                }}
                value={mobileNumber}
                setValue={setMobileNumber}
              />
            </FormControl>
            <Button
              className="btn btn-sm align-stretch"
              variant="outlined"
              style={{ padding: "2px 8px" }}
              onClick={sendSMSVoice}
            >
              <SmsIcon className="font-size-20" />
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            padding="10px 0 0px 0"
            justifyContent="center"
          >
            {/* <FormControl>
              <Button
                type="submit"
                variant="outlined"
                className="btn btn-secondary"
                onClick={handleCloseResendInvoice}
              >
                Close
              </Button>
            </FormControl> */}
          </Stack>
        </DialogContent>
      </BootstrapDialog>

      {/* Select Jobcard Status */}
      <BootstrapDialog
        onClose={handleJobcardStatusClose}
        aria-labelledby="customized-dialog-title"
        open={openJobcardStatus}
        className=""
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleJobcardStatusClose}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Select Jobcard Status
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className="font-size-14">
          <Stack
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            direction="column"
          >
            <Stack direction="row" alignItems="baseline">
              <h5>{sendNotificationLabelText}</h5>
              <FormControlLabel
                style={{ margin: "0" }}
                control={
                  <Switch
                    checked={isSendNotification}
                    onChange={handleSendNotificationChange}
                  />
                }
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={jobcardStatusVal}
              onChange={(e) => setJobcardStatusVal(e.target.value)}
            >
              {!Utils.IsNullOrEmptyArray(jobcardStatusList) &&
                jobcardStatusList.map((item) => (
                  <FormControlLabel
                    value={item.value}
                    control={<Radio name="jobcardStatus" />}
                    label={item.text}
                  />
                ))}
            </RadioGroup>
            {jobcardStatusVal == VisitStatuses.Completed &&
              localSettings.branch.isOdometerReadingsMandatory && (
                <Stack direction="row" spacing={2} className="mt-2">
                  <FormControl fullWidth>
                    <TextField
                      label="Odometer Reading In"
                      variant="outlined"
                      disabled
                      value={selectedJobcard.vehicleDetails.kmDriven}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label="Odometer Reading Out"
                      variant="outlined"
                      required
                      value={odometerOut}
                      onChange={(e) => setOdometerOut(e.target.value)}
                    />
                  </FormControl>
                </Stack>
              )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            className="mt-2"
          >
            <Button
              className="btn btn-secondary"
              variant="contained"
              style={{ backgroundColor: "gray" }}
              onClick={handleJobcardStatusClose}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary white-text"
              variant="contained"
              onClick={submitJobcardStatus}
            >
              Ok
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>

      {/* Refund */}
      <BootstrapDialog
        onClose={handleCloseRefund}
        aria-labelledby="customized-dialog-title"
        open={openRefund}
        className=""
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseRefund}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Refund - {refundInvoice.invoiceNumber}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className="font-size-14">
          <FormControl fullWidth>
            <TextField
              label="Refund Reason *"
              variant="outlined"
              onChange={(e) => setRefundReason(e.target.value)}
            />
          </FormControl>
          {refundServiceItems.map((row, index) => (
            <Grid container xs={12} className="card mt-1">
              <Grid container xs={1} padding="10px">
                <input
                  type="checkbox"
                  name="serviceItemCode"
                  disabled={!row.canCheck}
                  onChange={(e) => handleCheckboxChange(index, e)}
                  checked={row.isChecked}
                />
              </Grid>
              <Grid container xs={11} padding="10px">
                <Grid xs={1} alignSelf="center">
                  <Typography level="h4" component="h4" fontSize="14px">
                    {row.refundableQuantity}
                  </Typography>
                </Grid>
                <Grid xs={9} className="">
                  <Typography
                    level="h2"
                    className="h2"
                    component="h4"
                    fontWeight="600"
                    fontSize="16px"
                  >
                    {row.description}
                  </Typography>
                </Grid>
                <Grid xs={2} className="text-right" alignSelf="center">
                  <Typography
                    level="h4"
                    component="h4"
                    fontWeight="600"
                    fontSize="14px"
                  >
                    {Utils.GetCurrency(
                      row.calculatedRefundTotal,
                      localSettings.country
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            spacing={2}
            padding="10px 0 0px 0"
            justifyContent="center"
          >
            <Button
              className="btn btn-secondary"
              variant="contained"
              style={{ backgroundColor: "gray" }}
              onClick={handleCloseRefund}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary white-text"
              variant="contained"
              onClick={submitRefundItems}
            >
              Refund {Utils.GetCurrency(refundSpend, localSettings.country)}
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>

      {/* Success Modal */}
      <SuccessDialog
        open={openSuccessModal}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />

      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Visits} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <Grid flex="1" spacing={2}>
                <Grid container className="title-sec">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title"
                  >
                    {isBackNavigationEnabled && (
                      <Button onClick={backNavigation} className="back-btn">
                        <NavigateBeforeIcon />
                      </Button>
                    )}
                    Visits
                  </Typography>

                  <Stack
                    className="cs-form text-left p-0 search-form"
                    direction="row"
                    alignSelf="center"
                    spacing={1}
                  >
                    {Utils.IsNullOrEmpty(visitStatus) && (
                      <FormGroup style={{ "flex-direction": "row" }}>
                        <label className="mr-1 mt-1">
                          <input
                            type="checkbox"
                            checked={isUnPaid}
                            onChange={handleUnpaidFilterItems}
                          />
                          <span className="ml-h">Unpaid</span>
                        </label>
                        <label className="mr-1 mt-1">
                          <input
                            type="checkbox"
                            checked={partiallyPaid}
                            onChange={handlePartiallyPaidFilterItems}
                          />
                          <span className="ml-h">Partially Paid</span>
                        </label>
                      </FormGroup>
                    )}
                    <Grid xs>
                      <TextField
                        className="form-control font-size-14"
                        placeholder="Search here..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyUp={(e) => handleKeyUp(e)}
                        style={{ "min-width": "300px" }}
                      />
                    </Grid>
                    <Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className="submitBtn cs-btn"
                        fullWidth="true"
                        onClick={handleSearchService}
                      >
                        <SearchIcon sx={{ fontSize: 24 }} />
                        <span className="btn-text">Search</span>
                      </Button>
                    </Grid>
                  </Stack>
                  <Grid paddingRight="15px" alignSelf="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className="btn btn-secondary refresh-icon"
                      fullWidth="true"
                      onClick={refreshVisits}
                    >
                      <RefreshIcon sx={{ fontSize: 24 }} />
                      <span className="btn-text ml-h">Refresh</span>
                    </Button>
                  </Grid>
                </Grid>

                <input
                  type="checkbox"
                  className="visit-filter-check"
                  id="filter-check"
                  style={{ display: "none" }}
                />
                <label
                  for="filter-check"
                  size="large"
                  className="btn btn-secondary refresh-icon visit-filter-check"
                >
                  <FilterAltOutlinedIcon />
                </label>
                <Grid className="card visit-filter-sec">
                  <Grid xs={12} className="font-size-14">
                    <Grid
                      container
                      direction="row"
                      className="font-size-14"
                      justifyContent="space-between"
                    >
                      <Grid xs={12} md={6}>
                        {!Utils.IsNullOrEmpty(visitStatus) &&
                          visitStatus !== VisitTypes.Delayed ? (
                          <Button
                            className="btn"
                            variant="outlined"
                            onClick={clearFilter}
                          >
                            <CloseIcon />
                            Clear Filter
                          </Button>
                        ) : (
                          <div>
                            {BusinessHelper.IsAutomobile(
                              localSettings.business
                            ) &&
                              !Utils.IsNullOrEmptyArray(categoryList) &&
                              categoryList.map((item) => (
                                <label className="mr-2">
                                  <input
                                    type="radio"
                                    checked={
                                      selectedCategory === item.visitType
                                    }
                                    onChange={() =>
                                      handleVisitTypeOnChange(item.visitType)
                                    }
                                  />
                                  <span className="ml-h">{item.title}</span>
                                </label>
                              ))}
                          </div>
                        )}
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        md={6}
                        direction="row"
                        className="font-size-14 visit-datepicker"
                        justifyContent="space-between"
                      >
                        <Grid xs>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              label="Search without date range"
                              style={{ "align-self": "end" }}
                              onChange={(e) => searchWithoutDateRange(e)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid className="text-right">
                          <DateRangePicker
                            showLeadingZeros={true}
                            format="dd/MM/yyyy"
                            onChange={onDateChange}
                            maxDate={new Date()}
                            value={selectedDate}
                            locale={
                              localSettings && localSettings.authenticated
                            }
                            clearIcon={null}
                            calendarIcon={
                              <CalendarMonthOutlinedIcon
                                style={{
                                  "font-size": "24px",
                                  "align-self": "center",
                                  color: "#666666",
                                }}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    className="font-size-14"
                    justifyContent="space-between"
                  >
                    <Grid
                      xs={12}
                      md={2}
                      marginTop="5px"
                      className="card text-center"
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isProfitVisible}
                            onChange={(e) =>
                              setIsProfitVisible(e.target.checked)
                            }
                          />
                        }
                        label="Show Profit"
                      />
                    </Grid>
                    {paymentTypes.map((elm, index) => {
                      if (elm.isVisible)
                        return (
                          <div
                            className="form-check ms-2 card payment-filter"
                            key={index}
                          >
                            <label
                              className="form-check-label"
                              style={{ margin: "10px 0" }}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={elm.isChecked}
                                onChange={(e) =>
                                  handleCategoryFilterOnChange(e, index)
                                }
                              />
                              &nbsp;
                              {elm.text}
                            </label>
                          </div>
                        );
                    })}
                  </Grid>
                </Grid>

                <Grid container xs={12} className="p-lg-2">
                  <TableContainer component={Paper} className="visit-table">
                    <Table stickyHeader area-lang="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Invoice #</TableCell>
                          <TableCell style={{ width: "200px" }}>
                            Customer Details
                          </TableCell>
                          <TableCell className="text-right">Points</TableCell>
                          <TableCell className="text-right">Cost</TableCell>
                          <TableCell className="text-right">Discount</TableCell>
                          <TableCell className="text-right">Tax</TableCell>
                          {isProfitVisible && (
                            <TableCell className="text-right">Profit</TableCell>
                          )}
                          <TableCell
                            className="text-right"
                            style={{ width: "120px" }}
                          >
                            Refund
                          </TableCell>
                          <TableCell className="text-right">Revenue</TableCell>
                          <TableCell
                            style={{ width: "0px", padding: "0" }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedList.map((row, index) => (
                          <TableRow
                            onClick={(event) =>
                              passVisitorObj(
                                row,
                                Utils.GetDate(
                                  row.createdDT,
                                  localSettings.country
                                ) +
                                " " +
                                row.serviceDesc,
                                event
                              )
                            }
                          >
                            <TableCell
                              data-status={row.status}
                              className="visit-card"
                            >
                              <Stack
                                style={{ "text-align": "center" }}
                                direction="row"
                              >
                                <Box
                                  component="img"
                                  src={
                                    row.visitType === "Checkout"
                                      ? Checkout_Icon
                                      : Jobcard_Icon
                                  }
                                  alt="Checkout"
                                  style={{
                                    width: "24px",
                                  }}
                                />
                                &nbsp;
                                <span className="visit-type">
                                  {Utils.GetStatusText(row.status)}
                                </span>
                              </Stack>
                              <Stack spacing={2} py={1}>
                                {row.invoiceNumber}
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Typography
                                component="p"
                                className="m-0"
                                fontWeight="800"
                              >
                                {row.serviceDesc}
                              </Typography>
                              {getCustomerCell(row)}
                              {getEmailCell(row)}
                              {getMaskedMobileCell(row, localSettings.user)}
                            </TableCell>
                            <TableCell className="text-right">
                              <strong className="mb-txt">Points</strong>
                              {row.basePoints + row.bonusPoints}
                            </TableCell>
                            <TableCell className="text-right">
                              {/* {row.serviceDesc} <br />
                            {BusinessHelper.IsAutomobile(
                              localSettings.business
                            ) &&
                              !Utils.IsNullOrEmpty(
                                row.vehicleDetails.number
                              ) && (
                                <Typography
                                  component="p"
                                  className="font-size-14 mt-1"
                                >
                                  <strong>Vehicle: </strong>
                                  <span>{row.vehicleDetails.number}</span>
                                </Typography>
                              )}
                            <Typography component="p">
                              <strong>Created On: </strong>
                              {Utils.GetDate(
                                row.createdDT,
                                localSettings.country
                              )}
                            </Typography> */}
                              <strong className="mb-txt">Cost</strong>
                              <span>
                                {Utils.GetCurrency(
                                  row.spend,
                                  localSettings.country
                                )}
                              </span>
                            </TableCell>
                            <TableCell className="text-right">
                              <strong className="mb-txt">Discount</strong>
                              <span>
                                -
                                {Utils.GetCurrency(
                                  row.discount,
                                  localSettings.country
                                )}
                              </span>
                            </TableCell>
                            <TableCell className="text-right">
                              <strong className="mb-txt">Tax</strong>
                              {row.isTaxIncluded && (
                                <Typography component="p">
                                  <span>
                                    +
                                    {Utils.GetCurrency(
                                      row.tax,
                                      localSettings.country
                                    )}
                                  </span>
                                </Typography>
                              )}
                            </TableCell>
                            {isProfitVisible && (
                              <TableCell className="text-right">
                                <strong className="mb-txt">Profit</strong>
                                <Typography component="p">
                                  {Utils.GetCurrency(
                                    calculateProfit(row),
                                    localSettings.country
                                  )}
                                </Typography>
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              <strong className="mb-txt">Refund</strong>
                              {row.refundAmount > 0 && (
                                <Typography component="p" className="prize-tag">
                                  <span>
                                    -
                                    {Utils.GetCurrency(
                                      row.refundAmount,
                                      localSettings.country
                                    )}
                                  </span>
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell className="text-right">
                              <strong className="mb-txt">Revenue</strong>
                              <Typography
                                component="p"
                                fontWeight="600"
                                style={{ "font-size": "16px" }}
                              >
                                <span>
                                  {Utils.GetCurrency(
                                    row.paymentAmount - row.refundAmount,
                                    localSettings.country
                                  )}
                                </span>
                              </Typography>
                              {isBusinessCountryUAE === true &&
                                ((row.status === VisitStatuses.Delivered &&
                                  row.paidAmount === 0) ||
                                  row.status ===
                                  VisitStatuses.PartiallyPaid) && (
                                  <Typography
                                    component="p"
                                    fontWeight="600"
                                    style={{ "font-size": "12px" }}
                                  >
                                    <span>Paid Amount:</span>
                                    <span>
                                      {Utils.GetCurrency(
                                        row.paidAmount,
                                        localSettings.country
                                      )}
                                    </span>
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell style={{ padding: "0" }}>
                              <div className="menuList">
                                <ul>
                                  {row.visitType === VisitTypes.JobCard &&
                                    row.status !== VisitStatuses.Delivered &&
                                    row.status !==
                                    VisitStatuses.PaymentRefunded && (
                                      <li
                                        onClick={(e) =>
                                          handleJobcardStatusClick(row, e)
                                        }
                                      >
                                        <TaskAltIcon
                                          fontSize="large"
                                          style={{ color: "#8BC34A" }}
                                        />
                                        <span>Status</span>
                                      </li>
                                    )}
                                  {(row.status === VisitStatuses.Delivered ||
                                    row.status ===
                                    VisitStatuses.PaymentRefunded) && (
                                      <>
                                        <li
                                          onClick={(e) =>
                                            showReceipt(row.customerId, row.id, e)
                                          }
                                        >
                                          <ReceiptLongIcon
                                            fontSize="large"
                                            style={{ color: "#2196f3" }}
                                          />
                                          <span>Receipt</span>
                                        </li>
                                        <li
                                          onClick={(e) =>
                                            handleClickOpenResendInvoice(row, e)
                                          }
                                        >
                                          <ReplyIcon
                                            fontSize="large"
                                            style={{ color: "#607d8b" }}
                                          />
                                          <span>Resend Invoice</span>
                                        </li>
                                      </>
                                    )}
                                  {(row.status === VisitStatuses.Delivered ||
                                    row.status ===
                                    VisitStatuses.PartiallyPaid) && (
                                      <li
                                        onClick={(e) =>
                                          showInvoice(row.customerId, row.id, e)
                                        }
                                      >
                                        <DescriptionIcon
                                          fontSize="large"
                                          style={{ color: "#9c27b0" }}
                                        />
                                        <span>Invoice</span>
                                      </li>
                                    )}
                                  {!Utils.IsNullOrEmpty(row.paymentId) && (
                                    <li
                                      onClick={(e) =>
                                        handleClickPayments(row, e)
                                      }
                                    >
                                      <PaymentsIcon
                                        fontSize="large"
                                        style={{ color: "#00a050" }}
                                      />
                                      <span>Payments</span>
                                    </li>
                                  )}
                                  {row.status === VisitStatuses.Delivered && (
                                    <li
                                      onClick={(e) =>
                                        handleClickOpenRefund(row, e)
                                      }
                                    >
                                      <CurrencyExchangeIcon
                                        fontSize="large"
                                        style={{ color: "#ff9800" }}
                                      />
                                      <span>Refund</span>
                                    </li>
                                  )}
                                  {row.status !==
                                    VisitStatuses.PaymentRefunded &&
                                    row.status !== VisitStatuses.Delivered && (
                                      <li
                                        onClick={(e) => handleVoidClick(row, e)}
                                      >
                                        <DeleteOutlineIcon
                                          fontSize="large"
                                          style={{ color: "#ff0000" }}
                                        />
                                        <span>Void</span>
                                      </li>
                                    )}
                                </ul>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Visits;
