import "../Style.css";
import "../bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {
  styled,
  Radio,
  Switch,
  TextField,
  Button,
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  FormControlLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Utils from "../core/Utils";
import UserHelper from "../core/UserHelper";
import BusinessHelper from "../core/BusinessHelper";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import SideBarMenuItems from "./SideBarMenuItems";
import ConfirmPopup from "./common/ConfirmPopup";
import SettingsMenuItems from "./SettingsMenuItems";
import {
  SideBarMenuNames,
  SettingsMenuList,
  ConfirmTypes,
  VehicleTypes,
  India,
  UAE,
  USA,
  AlertSMSNotSupported,
} from "../core/Constants";
import { connectToStore } from "../data/store";
import { postAsync, getGeocodeAddress } from "../core/serviceClient";
import { setBranch, setBusiness } from "../data/localSettingsActions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import NumericTextField from "./common/controls/NumericTextField";
import moment from "moment";
import AutoMobileHelper from "../core/AutomobileHelper";
import CountryHelper from "../core/CountryHelper";
import {
  AddNewBranchPage,
  BranchSetupPage,
  LoginPage,
} from "../core/PageConstants";
import AddressField from "./common/controls/AddressField";
import { Regexes } from "../core/Regexes";
import { CanUseProductStockReport } from "../core/ModuleHelper";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const AddNewBranch = (props) => {
  document.title = "Add New Branch";
  const navigate = useNavigate();

  const location = useLocation();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [navigateTo, setNavigateTo] = useState(false);
  const [confirmFor, setConfirmFor] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  const [registrationId, setRegistrationId] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [automaticMessage, setAutomaticMessage] = useState(false);
  const [allowPartialPayments, setAllowPartialPayments] = useState(false);
  const [taxPercent, setTaxPercent] = useState("");
  const [corporate, setCorporate] = useState(false);
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [invoiceSuffix, setInvoiceSuffix] = useState("");
  const [contactName, setContactName] = useState("");
  const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState("");
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [receiptCaption, setReceiptCaption] = useState("");
  const [payments, setPayments] = useState(false);
  const [payouts, setPayouts] = useState(false);
  const [autoPay, setAutoPay] = useState(false);
  const [immediate, setImmediate] = useState(false);
  const [acceptReservations, setAcceptReservations] = useState("");
  const [businessOpen, setBusinessOpen] = useState(Utils.GetStartDate());
  const [businessClose, setBusinessClose] = useState(Utils.GetStartDate());
  const [maxDays, setMaxDays] = useState("");
  const [timeSlotIntMins, setTimeSlotIntMins] = useState("");
  const [reservationsPerTimeSlot, setReservationsPerTimeSlot] = useState("");
  const [reserveService, setReserveService] = useState(false);
  const [reservationAtUserLevel, setReservationAtUserLevel] = useState(false);
  const [reservationPaymentRefundable, setReservationPaymentRefundable] =
    useState(false);
  const [selectedBranch, setSelectedBranch] = useState({});
  const [pageTitle, setPageTitle] = useState("Add New Branch");
  const [taxIdLbl, setTaxIdLbl] = useState("Business Tax Id");
  const [vehicleList, setVehicleList] = useState([]);
  const [fetchServiceLocations, setFetchServiceLocations] = useState(false);
  const [isOdometerReadingsMandatory, setIsOdometerReadingsMandatory] =
    useState(false);
  const [businessCountry, setBusinessCountry] = useState("");
  const [openInvoice, setOpenInvoice] = useState(false);
  const [invoiceDesc, setInvoiceDesc] = useState("");
  const [invoiceTax, setInvoiceTax] = useState(false);
  const [invoiceActive, setInvoiceActive] = useState(false);
  const [invoiceLabels, setInvoiceLabels] = useState([]);
  const [reservationConfirmedByCustomer, setReservationConfirmedByCustomer] =
    useState(false);
  const [reservationPaymentRequired, setReservationPaymentRequired] =
    useState(false);
  const [reservationPaymentPercent, setReservationPaymentPercent] =
    useState("");
  const [sendServiceReminder, setSendServiceReminder] = useState(false);
  const [daysToRemindBeforeService, setDaysToRemindBeforeService] =
    useState("");
  const [serviceReminderTriggerTime, setServiceReminderTriggerTime] = useState(
    moment(Utils.GetStartDate(), "HH:mm:ss")
  );
  const [jobTypes, setJobTypes] = useState(AutoMobileHelper.GetJobTypes());
  const [inventoryItems, setInventoryItems] = useState(
    AutoMobileHelper.GetInventoryItems()
  );
  const [openJobType, setOpenJobType] = useState(false);
  const [regIdLength, setRegIdLength] = useState(0);
  const [jobType, setJobType] = useState("");
  const [openInventory, setOpenInventory] = useState(false);
  const [inventory, setInventory] = useState({});
  const [isAutomobile, setIsAutomobile] = useState(
    BusinessHelper.IsAutomobile()
  );
  const [selectedVehicle, setSelectedVehicle] = useState(VehicleTypes.FourWheeler);
  const [branchAddressISOCode, setBranchAddressISOCode] = useState({});
  const [isBillingInventoryTrack, setIsBillingInventoryTrack] = useState(false);
  const [isBackDateInvoice, setIsBackDateInvoice] = useState(false);
  const [isProductStockReport, setIsProductStockReport] = useState(false);

  // payment link settings
  const [allowPaymentLinks, setAllowPaymentLinks] = useState(false);

  //Description in Invoice
  const [isDescriptionInInvoice, setIsDescriptionInInvoice] = useState(false);

  let vehicles = [
    {
      value: VehicleTypes.FourWheeler,
      isChecked: false,
    },
    {
      value: VehicleTypes.TwoWheeler,
      isChecked: false,
    },
  ];

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (navigateTo == "Login") {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    } else if (navigateTo == "Branches") {
      navigate(BranchSetupPage.Path);
    }
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (confirmFor == "Invoice") {
        processInvoiceSave();
      }
      if (confirmFor == "Update") {
        branchSetupSubmit();
      }
    }
  };
  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(AddNewBranchPage, navigate, localSetting)) {
      moment.locale(CountryHelper.GetDateCulture(localSetting.country.Culture));
      setLocalSettings(localSetting);
      let country = CountryHelper.GetCountryObject(
        localSetting.business.branchLocations[0].countryCode
      );
      setBusinessCountry(country);
      var productStock = CanUseProductStockReport(localSetting.business);
      if (productStock === true) {
        setIsProductStockReport(true);
      }
      setBranchAddressISOCode(country.ISOCode);
      if (country.ShortName === India.ShortName) {
        setTaxIdLbl("GSTIN Number");
      }
      else if (country.ShortName === UAE.ShortName) {
        setTaxIdLbl("TRN No");
      }
      setRegIdLength(15);
      var branch = location.state && location.state.selectedBranch;
      if (!Utils.IsNullOrEmptyObject(branch)) {
        setSelectedBranch(branch);
        setPageTitle("Update Branch: " + branch.branchName);
        setBranchName(branch.branchName);
        setBranchAddress(branch.address);
        setPayments(branch.paymentsEnabled);
        setPayouts(branch.payoutsEnabled);
        setAutoPay(branch.autoPayEnabled);
        setAllowPartialPayments(branch.allowMultiplePayments);
        setIsDescriptionInInvoice(branch.showItemDescriptionInInvoice);
        setRegistrationId(branch.registrationId);
        setStateCode(branch.stateCode);
        setTaxPercent(branch.taxPercent);
        setAutomaticMessage(branch.sendAutomaticMessage);
        setCorporate(branch.corporate);
        setInvoicePrefix(branch.invoicePrefix);
        setInvoiceSuffix(branch.invoiceSuffix);
        setContactName(branch.contactName);
        setAdditionalPhoneNumber(branch.additionalPhoneNumber);
        setIsBillingInventoryTrack(branch.isBillingInventoryTrack);
        setIsBackDateInvoice(branch.invoiceToPreviousDate)
        setContactPhoneNumber(branch.contactPhoneNumber);
        setContactEmail(branch.contactEmailId);
        setReceiptCaption(branch.receiptCaption);
        setBusinessOpen(branch.serviceStartTime);
        setBusinessClose(branch.serviceEndTime);
        setSelectedVehicle(branch.vehicleType);
        setFetchServiceLocations(branch.fetchServiceLocations);
        setIsOdometerReadingsMandatory(branch.isOdometerReadingsMandatory);
        setAcceptReservations(branch.reservationsDisabled == false);
        setReserveService(branch.reserveProducts);
        setReservationAtUserLevel(branch.reservationAtUserLevel);
        setReservationConfirmedByCustomer(
          branch.reservationConfirmedByCustomer
        );
        setImmediate(branch.autoPayImmediate);
        setMaxDays(branch.reservationMaxDays);
        setTimeSlotIntMins(branch.reservationTimeInterval);
        setReservationsPerTimeSlot(branch.reservationCountPerSlot);
        setReservationPaymentRequired(branch.reservationPaymentRequired);
        setReservationPaymentPercent(branch.reservationPaymentPercent);
        setReservationPaymentRefundable(branch.reservationPaymentRefundable);
        setFetchServiceLocations(branch.fetchServiceLocations);
        setSendServiceReminder(branch.sendServiceReminder);
        setDaysToRemindBeforeService(branch.daysToRemindBeforeService);
        setServiceReminderTriggerTime(
          moment(branch.serviceReminderTriggerTime, "HH:mm:ss")
        );
        setInvoiceLabels(branch.invoiceLabels);
        if (!Utils.IsNullOrEmptyArray(branch.jobTypes)) {
          setJobTypes(branch.jobTypes);
        }
        if (!Utils.IsNullOrEmptyArray(branch.inventoryItem)) {
          var tempInventoryItems = [];
          Object.entries(branch.inventoryItem).forEach(([key, value]) => {
            tempInventoryItems.push({
              name: key,
              isChecked: value,
            });
          });
          setInventoryItems(tempInventoryItems);
        }
      } else {
        vehicles[0].isChecked = true;
      }
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMsg = "";
    var validRegistrationId = Utils.IsNullOrEmpty(registrationId);
    if (businessCountry.ShortName === India.ShortName) {
      if (!validRegistrationId) {
        validRegistrationId =
          registrationId.length === regIdLength &&
          Regexes.GST.test(registrationId);
      }
      if (!validRegistrationId) {
        errorMsg += "Please enter a valid GSTIN number.<br />";
      }
    }
    else if (businessCountry.ShortName === UAE.ShortName) {
      if (!validRegistrationId) {
        validRegistrationId = registrationId.length === regIdLength
      }
      if (!validRegistrationId) {
        errorMsg += "Please enter a valid TRN Number.<br />";
      }
    } else if ((businessCountry.ShortName === USA.ShortName && regIdLength === 15)) {
      if (!validRegistrationId) {
        validRegistrationId = registrationId.length === regIdLength
      }
      if (!validRegistrationId) {
        errorMsg += "Please enter a valid Business Tax Id.<br />";
      }
    }
    if (Utils.IsNullOrEmpty(branchName)) {
      errorMsg += "Please enter the Branch Name.<br />";
    }
    if (Utils.IsNullOrEmpty(branchAddress)) {
      errorMsg += "Please enter the Branch Address.<br />";
    }
    if (businessCountry == USA.ShortName && Utils.IsNullOrEmpty(taxPercent)) {
      errorMsg += "Please enter the Tax Percent.<br />";
    }
    if (Utils.IsNullOrEmpty(contactName)) {
      errorMsg += "Please enter the Contact Name.<br />";
    }
    if (Utils.IsNullOrEmpty(contactPhoneNumber)) {
      errorMsg += "Please enter the Contact Mobile Number.<br />";
    } else if (
      !Utils.IsMobileValid(contactPhoneNumber)
    ) {
      errorMsg += "Please enter the valid Contact Mobile Number.<br />";
    }
    if (
      !Utils.IsNullOrEmpty(additionalPhoneNumber) &&
      !Utils.IsMobileValid(additionalPhoneNumber)
    ) {
      errorMsg += "Please enter the valid Additional Phone Number.<br />";
    }
    if (Utils.IsNullOrEmpty(contactEmail)) {
      errorMsg += "Please enter the Contact Email.<br />";
    } else if (!Utils.IsEmailValid(contactEmail)) {
      errorMsg += "Please enter the valid Contact Email.<br />";
    }
    if (acceptReservations) {
      if (Utils.IsNullOrEmpty(maxDays)) {
        errorMsg += "Please enter the Max Days.<br />";
      }
      if (Utils.IsNullOrEmpty(timeSlotIntMins)) {
        errorMsg += "Please enter the Time Slot Interval Minutes.<br />";
      }
      if (Utils.IsNullOrEmpty(reservationsPerTimeSlot)) {
        errorMsg += "Please enter the Reservation Per Time Slot.<br />";
      }
    }
    if (businessCountry == USA.ShortName && reservationPaymentRequired) {
      errorMsg += "Please enter the Payment Percent.<br />";
    }
    if (businessOpen._isValid == false) {
      if (acceptReservations)
        errorMsg += "Business opening time is not valid.";
      else
        setBusinessOpen(Utils.GetStartDate());
    }
    if (businessClose._isValid == false) {
      if (acceptReservations)
        errorMsg += "Business opening time is not valid.";
      else
        setBusinessClose(Utils.GetStartDate());
    }

    if (acceptReservations && businessOpen > businessClose) {
      errorMsg += "Business opening time should be before closing time.<br />";
    }
    if (
      !Utils.IsNullOrEmptyArray(invoiceLabels) &&
      !invoiceLabels.some((item) => item.active === true)
    ) {
      errorMsg +=
        "At least one active invoice label required before proceeding.<br />";
    }
    if (serviceReminderTriggerTime._isValid == false) {
      if (sendServiceReminder)
        errorMsg += "Service reminder start time is not valid."
      else
        setServiceReminderTriggerTime(Utils.GetStartDate());
    }

    if (!Utils.IsNullOrEmpty(errorMsg)) {
      setMsgText(errorMsg);
      setMsgOpen(true);
    } else {
      setConfirmFor("Update");
      setConfirmText("Are you sure you want to save changes?");
      setConfirmOpen(true);
    }
  };

  async function branchSetupSubmit() {
    setIsLoading(true);
    let newBranch = {
      ...selectedBranch,
      branchName: branchName,
      address: branchAddress,
      allowMultiplePayments: allowPartialPayments,
      showItemDescriptionInInvoice: isDescriptionInInvoice,
      registrationId: Utils.ConvertToUpperCase(registrationId),
      stateCode: stateCode,
      taxPercent: Utils.ConvertToFloat(taxPercent),
      isBillingInventoryTrack: isBillingInventoryTrack,
      invoiceToPreviousDate: isBackDateInvoice,
      sendAutomaticMessage: automaticMessage,
      corporate: corporate,
      invoicePrefix: invoicePrefix,
      invoiceSuffix: invoiceSuffix,
      contactName: contactName,
      contactPhoneNumber: contactPhoneNumber,
      additionalPhoneNumber: additionalPhoneNumber,
      contactEmailId: Utils.ConvertToLowerCase(contactEmail),
      receiptCaption: receiptCaption,
      serviceStartTime: Utils.GetISODateTimeString(moment(businessOpen)),
      serviceEndTime: Utils.GetISODateTimeString(moment(businessClose)),
      reservationMaxDays: Utils.ConvertToInteger(maxDays),
      reservationCountPerSlot: Utils.ConvertToInteger(reservationsPerTimeSlot),
      reservationTimeInterval: Utils.ConvertToInteger(timeSlotIntMins),
      reservationsDisabled: acceptReservations,
      vehicleType: isAutomobile ? selectedVehicle : VehicleTypes.None,
      reservationPaymentRefundable: reservationPaymentRefundable,
      reservationConfirmedByCustomer: reservationConfirmedByCustomer,
      reservationPaymentPercent: Utils.ConvertToFloat(
        reservationPaymentPercent
      ),
      reservationAtUserLevel: reservationAtUserLevel,
      reserveProducts: reserveService,
      paymentsEnabled: payments,
      payoutsEnabled: payouts,
      autoPayEnabled: autoPay,
      autoPayImmediate: immediate,
      reservationPaymentRequired: reservationPaymentRequired,
      reservationsDisabled: acceptReservations == false,
      isOdometerReadingsMandatory: isAutomobile && isOdometerReadingsMandatory,
      daysToRemindBeforeService: Utils.ConvertToInteger(
        daysToRemindBeforeService
      ),
      serviceReminderTriggerTime: Utils.GetTimeOnly(
        moment(serviceReminderTriggerTime, "HH:mm:ss")
      ),
    };
    if (!Utils.IsNullOrEmptyObject(selectedBranch)) {
      newBranch.payout = selectedBranch.payout;
      newBranch.beacons = selectedBranch.beacons ? selectedBranch.beacons : [];
      if (selectedBranch.productsDT)
        newBranch.productsDT = selectedBranch.productsDT;
      newBranch.qrCodeUrl = selectedBranch.qrCodeUrl
        ? selectedBranch.qrCodeUrl
        : "";
      newBranch.reservationDocs = selectedBranch.reservationDocs
        ? selectedBranch.reservationDocs
        : [];
    } else {
      newBranch.reservationBlockedTimes = null;
      newBranch.productsDT = "0001-01-01T00:00:00";
      newBranch.beacons = null;
      newBranch.terminals = null;
      newBranch.payout = null;
      newBranch.status = "Active";
      newBranch.upi = null;
      newBranch.reservationDocs = null;
      newBranch.bankDetails = null;
      newBranch.serviceStartTime = "0001-01-01T00:00:00";
      newBranch.serviceEndTime = "0001-01-01T00:00:00";
      newBranch.serviceReminderTriggerTime = "00:00:00";
      var branchLocations = [...localSettings.business.branchLocations];
      var branches = branchLocations.sort((a, b) =>
        a.branchId > b.branchId ? -1 : 1
      );
      let tempBranchId = Utils.ConvertToInteger(branches[0].branchId) + 1;
      let strBranchId = Utils.ConvertToString(tempBranchId);
      if (strBranchId.length == 1) {
        newBranch.branchId = "00" + strBranchId;
      } else if (strBranchId.length == 2) {
        newBranch.branchId = "0" + strBranchId;
      } else newBranch.branchId = strBranchId;
    }
    if (Utils.IsNullOrEmpty(newBranch.countryCode))
      newBranch.countryCode = businessCountry.Code;
    if (isAutomobile) {
      newBranch.fetchServiceLocations = fetchServiceLocations;
      newBranch.sendServiceReminder = sendServiceReminder;
      newBranch.jobTypes = jobTypes;
      if (!Utils.IsNullOrEmptyArray(inventoryItems)) {
        let inventories = Object.fromEntries(
          inventoryItems.map((c) => [c.name, c.isChecked])
        );
        newBranch.inventoryItem = inventories;
      }
    } else {
      newBranch.fetchServiceLocations = false;
      newBranch.sendServiceReminder = false;
    }
    if (
      businessCountry &&
      (businessCountry == India.ShortName || businessCountry == UAE.ShortName)
    ) {
      let selectedVehicle = vehicleList.filter((v) => v.isChecked === true);
      newBranch.vehicleType = isAutomobile
        ? selectedVehicle[0].value
        : VehicleTypes.None;
    }
    if (!Utils.IsNullOrEmptyArray(invoiceLabels))
      newBranch.invoiceLabels = invoiceLabels;
    else {
      newBranch.invoiceLabels = [
        {
          active: true,
          taxIncluded: true,
          description:
            "INV" + localSettings.business.branchLocations.length + 1,
          invoiceNumber: 0,
        },
      ];
    }
    if (!Utils.IsNullOrEmpty(branchAddress)) {
      var geocodeResponse = await getGeocodeAddress(branchAddress);
      var locality = "",
        county = "",
        state = "",
        country = "",
        zipcode = "",
        streetNumber = "",
        route = "",
        address = branchAddress;
      let lat = 0,
        lng = 0;
      if (
        !Utils.IsNullOrEmptyObject(geocodeResponse) &&
        geocodeResponse.status === "OK" &&
        !Utils.IsNullOrEmptyArray(geocodeResponse.results) &&
        !Utils.IsNullOrEmptyObject(geocodeResponse.results[0])
      ) {
        let geoResult = geocodeResponse.results[0];
        address = geoResult.formatted_address;
        if (
          geoResult.address_components
            .map((c) => c.types)
            .includes("street_number") === true
        ) {
          var ac = geoResult.address_components.find((c) =>
            c.types.includes("street_number")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) streetNumber = ac.long_name;
        }
        if (
          geoResult.address_components.map((c) => c.types).includes("route") ===
          true
        ) {
          var ac = geoResult.address_components.find((c) =>
            c.types.includes("route")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) route = ac.long_name;
        }
        if (
          geoResult.address_components
            .map((c) => c.types)
            .includes("locality") === true
        ) {
          var ac = geoResult.address_components.find((c) =>
            c.types.includes("locality")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) locality = ac.long_name;
        }
        if (
          geoResult.address_components
            .map((c) => c.types)
            .includes("administrative_area_level_1") === true
        ) {
          var ac = geoResult.address_components.find((c) =>
            c.types.includes("administrative_area_level_1")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) state = ac.long_name;
        }
        if (
          geoResult.address_components
            .map((c) => c.types)
            .includes("administrative_area_level_2") === true
        ) {
          var ac = geoResult.address_components.find((c) =>
            c.types.includes("administrative_area_level_2")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) county = ac.long_name;
        }

        if (
          geoResult.address_components
            .map((c) => c.types)
            .includes("country") === true
        ) {
          var ac = geoResult.address_components.find((c) =>
            c.types.includes("country")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) country = ac.short_name;
        }

        if (
          geoResult.address_components
            .map((c) => c.types)
            .includes("postal_code") === true
        ) {
          var ac = geoResult.address_components.find((c) =>
            c.types.includes("postal_code")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) zipcode = ac.long_name;
        }
        if (geoResult.geometry && geoResult.geometry.location) {
          lat = geoResult.geometry.location.lat;
          lng = geoResult.geometry.location.lng;
        }
      }
      newBranch.address =
        businessCountry && businessCountry == USA.ShortName
          ? address
          : branchAddress;
      newBranch.addressLine1 = streetNumber + " " + route;
      newBranch.city = locality;
      newBranch.state = state;
      newBranch.zipCode = zipcode;
      newBranch.country = country;
      newBranch.latitude = lat;
      newBranch.longitude = lng;
    }
    let response = await postAsync("Business/SaveBranch", {
      BusinessId: localSettings.business.id,
      Branch: newBranch,
    });
    if (response.isNavigateToLogin) setNavigateTo("Login");
    setIsLoading(false);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
    } else if (response.data) {
      setNavigateTo("Branches");
      setBusiness(response.data.business, dispatch);
      var updatedBranch = response.data.business.branchLocations.find(
        (bl) => bl.branchId == newBranch.branchId
      );
      setBranch(updatedBranch, dispatch);
      setMsgText("Changes saved successfully.");
      setMsgOpen(true);
    }
  }

  const processInvoiceSave = () => {
    var activeCount = Utils.IsNullOrEmptyArray(invoiceLabels)
      ? 0
      : invoiceLabels.filter((i) => i.active).length;
    if (invoiceActive && activeCount >= 5) {
      setMsgText("Maximum upto 5 active invoice labels can be added.");
      setMsgOpen(true);
      return;
    }
    let invoiceList = [...invoiceLabels];
    invoiceList.push({
      description: invoiceDesc,
      taxIncluded: invoiceTax,
      active: invoiceActive,
    });
    setInvoiceLabels(invoiceList);
  };
  const handleAddInvoice = () => {
    setInvoiceDesc("");
    setInvoiceActive(false);
    setInvoiceTax(false);
    setOpenInvoice(true);
  };
  const handleInvoiceClose = () => {
    setOpenInvoice(false);
  };
  const handleJobTypeClose = () => {
    setOpenJobType(false);
  };
  const handleInventoryClose = () => {
    setOpenInventory(false);
  };
  const handleJobTypeAdd = () => {
    setJobType("");
    setOpenJobType(true);
  };
  const handleInventoryAdd = () => {
    setInventory("");
    setOpenInventory(true);
  };
  const handleJobTypeSave = () => {
    if (!Utils.IsNullOrEmpty(jobType)) {
      let jobtypes = [...jobTypes];
      jobtypes.push(jobType);
      setJobTypes(jobtypes);
    }
    setOpenJobType(false);
  };
  const handleJobTypeDelete = (index) => {
    let jobtypes = [...jobTypes];
    jobtypes.splice(index, 1);
    setJobTypes(jobtypes);
  };
  const handleInventorySave = () => {
    if (!Utils.IsNullOrEmpty(inventory)) {
      let inventories = [...inventoryItems];
      inventories.push({ name: inventory, isChecked: false });
      setInventoryItems(inventories);
    }
    setOpenJobType(false);
  };
  const handleInventoryDelete = (index) => {
    let inventories = [...inventoryItems];
    inventories.splice(index, 1);
    setInventoryItems(inventories);
  };
  const handleCommentsChange = (e, index) => {
    let inventories = [...inventoryItems];
    inventories[index].isChecked = e.target.checked;
    setInventoryItems(inventories);
  };

  const handleInvoiceSave = () => {
    if (Utils.IsNullOrEmpty(invoiceDesc)) {
      setMsgText("Please enter a valid description.");
      setMsgOpen(true);
    } else if (
      !Utils.IsNullOrEmptyArray(invoiceLabels) &&
      invoiceLabels.some((item) => item.description === invoiceDesc)
    ) {
      setMsgText("Please enter a different description name.");
      setMsgOpen(true);
    } else {
      setOpenInvoice(false);
      setConfirmFor("Invoice");
      setConfirmText(
        "Invoice label description and tax cannot be changed later. Do you wish to continue?"
      );
      setConfirmOpen(true);
    }
  };

  const handleActiveChange = (event, index) => {
    let invoiceList = [...invoiceLabels];
    invoiceList[index].active = event.target.checked;
    setInvoiceLabels(invoiceList);
  };
  const handleVehicleChange = (vehicleType) => {
    setSelectedVehicle(vehicleType);
  };

  const handleServiceReminder = (event) => {
    if (!localSettings.country.SMSEnabled && event.target.checked) {
      setMsgOpen(true);
      setMsgText(AlertSMSNotSupported);
      setSendServiceReminder(false);
      return;
    }
    setSendServiceReminder(event.target.checked);
  }

  const handleAutomaticMessage = (event) => {
    if (!localSettings.country.SMSEnabled && event.target.checked) {
      setMsgOpen(true);
      setMsgText(AlertSMSNotSupported);
    }
    setAutomaticMessage(event.target.checked)
  }

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />

      {/*Invoice Label*/}
      <BootstrapDialog
        onClose={handleInvoiceClose}
        aria-labelledby="customized-dialog-title"
        open={openInvoice}
        className=""
        sx={{ "& .MuiDialog-paper": { width: 400, maxHeight: 300 } }}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleInvoiceClose}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3 text-center"
          >
            Invoice Label
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent className="font-size-14">
          <Grid flex="1" spacing={2} className="mt-1">
            <FormControl fullWidth>
              <TextField
                id="description"
                label="Description"
                variant="outlined"
                value={invoiceDesc}
                onChange={(e) => setInvoiceDesc(e.target.value)}
              />
            </FormControl>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      checked={invoiceTax}
                      onChange={(e) => setInvoiceTax(e.target.checked)}
                    />
                  }
                  label="Tax"
                />
              </FormControl>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      checked={invoiceActive}
                      onChange={(e) => setInvoiceActive(e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </FormControl>
            </Stack>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            className="mt-1"
          >
            <FormControl>
              <Button
                className="btn btn-primary white-text"
                variant="outlined"
                onClick={handleInvoiceSave}
              >
                Save
              </Button>
            </FormControl>
          </Stack>
        </DialogContent>
      </BootstrapDialog>

      {/*JobTypes*/}
      <BootstrapDialog
        onClose={handleJobTypeClose}
        aria-labelledby="customized-dialog-title"
        open={openJobType}
        className=""
        sx={{ "& .MuiDialog-paper": { width: 400, maxHeight: 300 } }}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleJobTypeClose}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3 text-center"
          >
            Add Job Type
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent className="font-size-14">
          <Grid flex="1" className="mt-1">
            <FormControl fullWidth>
              <TextField
                id="jobType"
                label="Job Type"
                variant="outlined"
                value={jobType}
                onChange={(e) => setJobType(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            className="mt-1"
          >
            <FormControl>
              <Button
                className="btn btn-primary white-text"
                variant="outlined"
                onClick={handleJobTypeSave}
              >
                Save
              </Button>
            </FormControl>
          </Stack>
        </DialogContent>
      </BootstrapDialog>

      {/*Inventory*/}
      <BootstrapDialog
        onClose={handleInventoryClose}
        aria-labelledby="customized-dialog-title"
        open={openInventory}
        className=""
        sx={{ "& .MuiDialog-paper": { width: 400, maxHeight: 300 } }}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleInventoryClose}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3 text-center"
          >
            Add Inventory Item
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent className="font-size-14">
          <Grid flex="1" spacing={2} className="mt-1">
            <FormControl fullWidth>
              <TextField
                id="inventory"
                label="Inventory Item"
                variant="outlined"
                value={inventory}
                onChange={(e) => setInventory(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            className="mt-1"
          >
            <FormControl>
              <Button
                className="btn btn-primary white-text"
                variant="outlined"
                onClick={handleInventorySave}
              >
                Save
              </Button>
            </FormControl>
          </Stack>
        </DialogContent>
      </BootstrapDialog>

      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec white-bg">
            <Grid container direction="row">
              <SettingsMenuItems
                selectedMenu={SettingsMenuList.BusinessSettings.BranchSetup}
              />
              <Grid container className="title-sec title-xs">
                <Typography
                  level="h2"
                  component="h2"
                  fontWeight="600"
                  fontSize="2rem"
                  className="page-title text-center"
                >
                  {pageTitle}
                </Typography>
              </Grid>
              <Grid flex="1" spacing={2} className="form-sec">
                <form
                  method="post"
                  className="cs-form text-left"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  style={{ paddingBottom: "30px" }}
                >
                  <Stack sx={{ minWidth: 120 }} spacing={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="branchName"
                        label="Branch Name"
                        variant="outlined"
                        error={!branchName}
                        value={branchName}
                        onChange={(e) => setBranchName(e.target.value)}
                      />
                    </FormControl>
                    {!Utils.IsNullOrEmpty(branchAddressISOCode) && (<FormControl fullWidth>
                      <AddressField
                        value={branchAddress}
                        setValue={setBranchAddress}
                        countryISoCode={branchAddressISOCode}
                        isValidate={true}
                        label="Branch Address"
                        variant="outlined"
                        required
                        id="branchAddress"
                      />
                    </FormControl>)}
                    {!BusinessHelper.IsSalon() && (
                      <FormControl fullWidth>
                        <TextField
                          id="registrationId"
                          label={taxIdLbl}
                          variant="outlined"
                          value={registrationId}
                          onChange={(e) => setRegistrationId(e.target.value.toUpperCase())}
                          inputProps={{ maxLength: 15 }}
                        />
                      </FormControl>
                    )}
                    {!BusinessHelper.IsSalon() &&
                      businessCountry &&
                      businessCountry == India.ShortName && (
                        <FormControl fullWidth>
                          <TextField
                            id="GSTNStateCode"
                            label="GSTN State Code"
                            variant="outlined"
                            value={stateCode}
                            onChange={(e) => setStateCode(e.target.value)}
                          />
                        </FormControl>
                      )}
                    {!BusinessHelper.IsSalon() && (
                      <FormControl fullWidth>
                        <Stack direction="row" alignItems={"center"}>
                          <Typography
                            level="h3"
                            component="h3"
                            fontWeight="600"
                            fontSize="2rem"
                            className="h3"
                          >
                            Vehicles Handled
                          </Typography>
                          <Radio
                            disabled={!Utils.IsNullOrEmptyObject(selectedBranch)}
                            checked={selectedVehicle === VehicleTypes.FourWheeler}
                            onClick={() => handleVehicleChange(VehicleTypes.FourWheeler)}
                          />
                          <Typography
                            level="h4"
                            component="h4"
                            fontSize="2rem"
                            className="h4"
                          >
                            Four Wheeler
                          </Typography>
                          <Radio
                            disabled={!Utils.IsNullOrEmptyObject(selectedBranch)}
                            checked={selectedVehicle === VehicleTypes.TwoWheeler}
                            onClick={() => handleVehicleChange(VehicleTypes.TwoWheeler)}
                          />
                          <Typography
                            level="h4"
                            component="h4"
                            fontSize="2rem"
                            className="h4"
                          >
                            Two Wheeler
                          </Typography>
                        </Stack>
                      </FormControl>
                    )}
                    <Stack direction="row" spacing={2}>
                      {isAutomobile && (
                        <>
                          <FormControl fullWidth>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={fetchServiceLocations}
                                  onChange={(e) =>
                                    setFetchServiceLocations(e.target.checked)
                                  }
                                />
                              }
                              label="Record Service Location"
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isOdometerReadingsMandatory}
                                  onChange={(e) =>
                                    setIsOdometerReadingsMandatory(
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label="Odometer Mandatory"
                            />
                          </FormControl>
                        </>
                      )}
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={automaticMessage}
                              onChange={(e) => handleAutomaticMessage(e)}
                            />
                          }
                          label="Send Automatic Message"
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isDescriptionInInvoice}
                              onChange={(e) =>
                                setIsDescriptionInInvoice(e.target.checked)
                              }
                            />
                          }
                          label="Allow Description In Invoice"
                        />
                      </FormControl>
                      {isProductStockReport && (
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isBillingInventoryTrack}
                                onChange={(e) =>
                                  setIsBillingInventoryTrack(e.target.checked)
                                }
                              />
                            }
                            label="Billing Inventory Track"
                          />
                        </FormControl>
                      )}
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isBackDateInvoice}
                              onChange={(e) =>
                                setIsBackDateInvoice(e.target.checked)
                              }
                            />
                          }
                          label="Backdate Invoice"
                        />
                      </FormControl>
                    </Stack>
                    {businessCountry && businessCountry == USA.ShortName && (
                      <Stack direction="row" spacing={2}>
                        <FormControl fullWidth>
                          <TextField
                            id="taxPercent"
                            label="Tax Percent"
                            variant="outlined"
                            value={taxPercent}
                            required
                            onChange={(e) => setTaxPercent(e.target.value)}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={corporate}
                                onChange={(e) => setCorporate(e.target.value)}
                              />
                            }
                            label="Corporate"
                          />
                        </FormControl>
                      </Stack>
                    )}

                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Payment
                    </Typography>
                    <Stack className="card" padding="10px">
                      <Stack direction="row">
                        <FormControl fullWidth>
                          <Box>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={allowPaymentLinks}
                                  onChange={(e) =>
                                    setAllowPaymentLinks(e.target.checked)
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  name="Allow Payment Links"
                                  color="primary"
                                />
                              }
                              label="Allow Payment Links"
                              labelPlacement="end"
                            />
                          </Box>
                        </FormControl>
                        <FormControl fullWidth>
                          <Box>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={allowPartialPayments}
                                  onChange={(e) => setAllowPartialPayments(e.target.checked)}
                                  InputLabelProps={{ shrink: true }}
                                  name="Allow Partial Payment"
                                  color="primary"
                                />
                              }
                              label="Allow Partial Payment"
                              labelPlacement="end"
                            />
                          </Box>
                        </FormControl>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      alignContent="space-between"
                      justifyContent="space-between"
                    >
                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="600"
                        fontSize="2rem"
                        className="h3"
                      >
                        Invoice Label
                      </Typography>
                      <IconButton
                        name="addInvoiceLabel"
                        onClick={() => {
                          handleAddInvoice();
                        }}
                      >
                        <AddCircleIcon fontSize="large" />
                      </IconButton>
                    </Stack>
                    {!Utils.IsNullOrEmptyArray(invoiceLabels) && (
                      <TableContainer component={Paper} className="data-grid">
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="invoice table"
                        >
                          <TableBody>
                            {invoiceLabels.map((row, index) => (
                              <TableRow
                                key={row.invoiceNumber}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.description}
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={row.taxIncluded}
                                          disabled
                                        />
                                      }
                                      label="Tax"
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={row.active}
                                          onChange={(e) =>
                                            handleActiveChange(e, index)
                                          }
                                        />
                                      }
                                      label="Active"
                                    />
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}

                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          id="invoicePrefix"
                          label="Invoice Prefix"
                          variant="outlined"
                          value={invoicePrefix}
                          onChange={(e) => setInvoicePrefix(e.target.value)}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="invoiceSuffix"
                          label="Invoice Suffix"
                          variant="outlined"
                          value={invoiceSuffix}
                          onChange={(e) => setInvoiceSuffix(e.target.value)}
                        />
                      </FormControl>
                    </Stack>
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Contact Info
                    </Typography>
                    <FormControl fullWidth>
                      <TextField
                        id="contactName"
                        label="Name "
                        variant="outlined"
                        error={!contactName}
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                      />
                    </FormControl>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <NumericTextField
                          id="contactPhoneNumber"
                          label="Mobile Number "
                          type="tel"
                          variant="outlined"
                          inputProps={{
                            maxLength: CountryHelper.GetMobileNumberMaxLength(),
                          }}
                          error={!Utils.IsMobileValid(contactPhoneNumber)}
                          value={contactPhoneNumber}
                          setValue={setContactPhoneNumber}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <NumericTextField
                          id="additionalPhoneNumber"
                          label="Additional Phone Number"
                          type="tel"
                          variant="outlined"
                          inputProps={{
                            maxLength: CountryHelper.GetMobileNumberMaxLength(),
                          }}
                          value={additionalPhoneNumber}
                          setValue={setAdditionalPhoneNumber}
                        />
                      </FormControl>
                    </Stack>
                    <FormControl fullWidth>
                      <TextField
                        id="contactEmail"
                        label="Email "
                        type="email"
                        variant="outlined"
                        value={contactEmail}
                        error={!contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        id="receiptCaption"
                        label="Receipt Footer Caption"
                        variant="outlined"
                        value={receiptCaption}
                        onChange={(e) => setReceiptCaption(e.target.value)}
                      />
                    </FormControl>
                    {businessCountry && businessCountry == USA.ShortName && (
                      <>
                        <Typography
                          level="h3"
                          component="h3"
                          fontWeight="600"
                          fontSize="2rem"
                          className="h3"
                        >
                          Payment Settings
                        </Typography>
                        <Stack direction="row" spacing={2}>
                          <FormControl fullWidth>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={payments}
                                  onChange={(e) => setPayments(e.target.value)}
                                />
                              }
                              label="Payments"
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={payouts}
                                  onChange={(e) => setPayouts(e.target.value)}
                                />
                              }
                              label="Payouts"
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={autoPay}
                                  onChange={(e) => setAutoPay(e.target.value)}
                                />
                              }
                              label="Auto Pay"
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={immediate}
                                  onChange={(e) => setImmediate(e.target.value)}
                                />
                              }
                              label="Immediate"
                            />
                          </FormControl>
                        </Stack>
                      </>
                    )}
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Reservation Settings
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={acceptReservations}
                              onChange={(e) =>
                                setAcceptReservations(e.target.checked)
                              }
                            />
                          }
                          label="Accept Reservations"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            id="businessOpen"
                            label="Business Open"
                            value={moment(businessOpen)}
                            onChange={(newValue) => setBusinessOpen(newValue)}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            id="businessClose"
                            label="Business Close"
                            value={moment(businessClose)}
                            onChange={(newValue) => setBusinessClose(newValue)}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        {acceptReservations ? (
                          <>
                            <FormControl fullWidth>
                              <NumericTextField
                                id="maxDays"
                                label="Max Days"
                                variant="outlined"
                                inputProps={{ maxLength: 5 }}
                                value={maxDays}
                                error={!maxDays}
                                setValue={setMaxDays}
                              />
                            </FormControl>
                          </>) : (
                          <>
                            <FormControl fullWidth>
                              <NumericTextField
                                id="maxDays"
                                label="Max Days"
                                variant="outlined"
                                inputProps={{ maxLength: 5 }}
                                value={maxDays}
                                setValue={setMaxDays}
                              />
                            </FormControl>
                          </>)}
                      </FormControl>
                      <FormControl fullWidth>

                        {acceptReservations ? (
                          <>
                            <NumericTextField
                              id="timeSlotIntMins"
                              label="Time Slot Interval Minutes"
                              variant="outlined"
                              inputProps={{ maxLength: 3 }}
                              value={timeSlotIntMins}
                              error={!timeSlotIntMins}
                              setValue={setTimeSlotIntMins}
                            />
                          </>) : (
                          <>
                            <NumericTextField
                              id="timeSlotIntMins"
                              label="Time Slot Interval Minutes"
                              variant="outlined"
                              inputProps={{ maxLength: 3 }}
                              value={timeSlotIntMins}
                              setValue={setTimeSlotIntMins}
                            />
                          </>)}
                      </FormControl>
                      <FormControl fullWidth>
                        {acceptReservations ? (
                          <>
                            <NumericTextField
                              id="reservationsPerTimeSlot"
                              label="Reservations Per Time Slot"
                              variant="outlined"
                              inputProps={{ maxLength: 3 }}
                              value={reservationsPerTimeSlot}
                              error={!reservationsPerTimeSlot}
                              setValue={setReservationsPerTimeSlot}
                            />
                          </>) : (
                          <>
                            <NumericTextField
                              id="reservationsPerTimeSlot"
                              label="Reservations Per Time Slot"
                              variant="outlined"
                              inputProps={{ maxLength: 3 }}
                              value={reservationsPerTimeSlot}
                              setValue={setReservationsPerTimeSlot}
                            />
                          </>)}
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={reserveService}
                              onChange={(e) =>
                                setReserveService(e.target.checked)
                              }
                            />
                          }
                          label="Reserve Service"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={reservationAtUserLevel}
                              onChange={(e) =>
                                setReservationAtUserLevel(e.target.checked)
                              }
                            />
                          }
                          label="Customer can reserve with specific employee"
                        />
                      </FormControl>
                      {businessCountry && businessCountry == USA.ShortName && (
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={reservationConfirmedByCustomer}
                                onChange={(e) =>
                                  setReservationConfirmedByCustomer(
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label="Customer to confirm reservation"
                          />
                        </FormControl>
                      )}
                    </Stack>
                  </Stack>
                  {businessCountry && businessCountry == USA.ShortName && (
                    <>
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={reservationPaymentRequired}
                              onChange={(e) =>
                                setReservationPaymentRequired(e.target.checked)
                              }
                            />
                          }
                          label="Payment Required"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        {reservationPaymentRequired ? (
                          <>
                            <NumericTextField
                              id="reservationPaymentPercent"
                              label="Payment Percent"
                              variant="outlined"
                              inputProps={{ maxLength: 5 }}
                              value={reservationPaymentPercent}
                              error={!reservationPaymentPercent}
                              setValue={setReservationPaymentPercent}
                            />
                          </>) : (
                          <>
                            <NumericTextField
                              id="reservationPaymentPercent"
                              label="Payment Percent"
                              variant="outlined"
                              inputProps={{ maxLength: 5 }}
                              value={reservationPaymentPercent}
                              setValue={setReservationPaymentPercent}
                            />
                          </>)}
                        <NumericTextField
                          id="reservationPaymentPercent"
                          label={
                            reservationPaymentRequired
                              ? "Payment Percent *"
                              : "Payment Percent"
                          }
                          variant="outlined"
                          inputProps={{ maxLength: 5 }}
                          value={reservationPaymentPercent}
                          setValue={setReservationPaymentPercent}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={reservationPaymentRefundable}
                              onChange={(e) =>
                                setReservationPaymentRefundable(
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Payment Refundable"
                        />
                      </FormControl>
                    </>
                  )}
                  {isAutomobile && (
                    <>
                      <Stack spacing={2}>
                        <Typography
                          level="h3"
                          component="h3"
                          fontWeight="600"
                          fontSize="2rem"
                          className="h3"
                        >
                          Service Reminder Settings
                        </Typography>
                        <Stack direction="row" spacing={2}>
                          <FormControl fullWidth>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={sendServiceReminder}
                                  onChange={(e) => handleServiceReminder(e)}
                                />
                              }
                              label="Send Service Reminder"
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <NumericTextField
                              id="daysToRemindBeforeService"
                              label="Days to remind before service"
                              variant="outlined"
                              inputProps={{ maxLength: 5 }}
                              value={daysToRemindBeforeService}
                              setValue={setDaysToRemindBeforeService}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <TimePicker
                                id="sendReminderAt"
                                label="Send Reminder At"
                                value={moment(
                                  serviceReminderTriggerTime,
                                  "HH:mm:ss"
                                )}
                                onChange={(newValue) =>
                                  setServiceReminderTriggerTime(newValue)
                                }
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Stack>
                      </Stack>
                      <Grid container spacing={2} className="mt-1">
                        <Grid item xs={12} md={6} lg={6} spacing={2}>
                          <Stack
                            spacing={2}
                            direction="row"
                            alignContent="space-between"
                            justifyContent="space-between"
                          >
                            <Typography
                              level="h3"
                              component="h3"
                              fontWeight="600"
                              fontSize="2rem"
                              className="h3"
                            >
                              Job Types
                            </Typography>
                            <IconButton
                              name="addInvoiceLabel"
                              onClick={() => {
                                handleJobTypeAdd();
                              }}
                            >
                              <AddCircleIcon fontSize="large" />
                            </IconButton>
                          </Stack>
                          {!Utils.IsNullOrEmptyArray(jobTypes) && (
                            <TableContainer
                              component={Paper}
                              className="data-grid"
                            >
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="job types table"
                              >
                                <TableBody>
                                  {jobTypes.map((row, index) => (
                                    <TableRow
                                      key={index}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{ width: "90px" }}
                                      >
                                        <IconButton
                                          name="deleteJobType"
                                          onClick={() => {
                                            handleJobTypeDelete(index);
                                          }}
                                          style={{ color: "red" }}
                                        >
                                          <DeleteOutlineIcon fontSize="large" />
                                        </IconButton>
                                      </TableCell>
                                      <TableCell>{row}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} spacing={2}>
                          <Stack
                            spacing={2}
                            direction="row"
                            alignContent="space-between"
                            justifyContent="space-between"
                          >
                            <Typography
                              level="h3"
                              component="h3"
                              fontWeight="600"
                              fontSize="2rem"
                              className="h3"
                            >
                              Inventory Items
                            </Typography>
                            <IconButton
                              name="addInventory"
                              onClick={() => {
                                handleInventoryAdd();
                              }}
                            >
                              <AddCircleIcon fontSize="large" />
                            </IconButton>
                          </Stack>
                          {!Utils.IsNullOrEmptyArray(inventoryItems) && (
                            <TableContainer
                              component={Paper}
                              className="data-grid td, .data-grid th"
                            >
                              <Table
                                sx={{ minWidth: 0, marginBottom: "10px" }}
                                aria-label="inventory table"
                              >
                                <TableBody>
                                  {inventoryItems.map((row, index) => (
                                    <TableRow
                                      key={index}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                      >
                                        <IconButton
                                          name="deleteInventory"
                                          onClick={() => {
                                            handleInventoryDelete(index);
                                          }}
                                          style={{ color: "red" }}
                                        >
                                          <DeleteOutlineIcon fontSize="large" />
                                        </IconButton>
                                      </TableCell>
                                      <TableCell>{row.name}</TableCell>
                                      <TableCell>
                                        <FormControl fullWidth>
                                          <FormControlLabel
                                            control={
                                              <Switch
                                                checked={row.isChecked}
                                                onChange={(e) =>
                                                  handleCommentsChange(e, index)
                                                }
                                              />
                                            }
                                            label="Comments"
                                          />
                                        </FormControl>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Stack>&nbsp;</Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    className="fixed-btn"
                  >
                    <FormControl>
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn btn-primary"
                      >
                        Save
                      </Button>
                    </FormControl>
                  </Stack>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AddNewBranch;
