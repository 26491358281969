import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "../../Style.css";
import "../../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Loader from "../common/Loader";
import MessagePopup from "../common/MessagePopUp";
import {
  GroupedVisitStatuses,
  MaxDateLimit,
  SideBarMenuNames,
} from "../../core/Constants";
import AmountTypography from "../common/controls/AmountTypography";
import Utils from "../../core/Utils";
import { postAsync } from "../../core/serviceClient";
import SideBarMenuItems from "../SideBarMenuItems";
import {
  GenerateGroupedVisitPage,
  GroupedVisitsPage,
  LoginPage,
  OverviewPage,
} from "../../core/PageConstants";
import UserHelper from "../../core/UserHelper";
import { connectToStore } from "../../data/store";

const filterTypes = {
  All: "All",
  Paid: "Paid",
  Unpaid: "Unpaid",
};

const GroupedVisits = () => {
  document.title = "Grouped Visits";
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allGroupedVisits, setAllGroupedVisits] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [filteredGroupedVisits, setFilteredGroupedVisits] = useState([]);
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [selectedFilter, setSelectedFilter] = useState(filterTypes.All);
  const [business, setBusiness] = useState({});
  const [branch, setBranch] = useState({});
  const [countryInfo, setCountryInfo] = useState({});

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = async () => {
    var _localSetting = connectToStore();
    if (
      UserHelper.CheckPermission(GroupedVisitsPage, navigate, _localSetting)
    ) {
      var _business = _localSetting.business;
      var _branch = _localSetting.branch;
      var _country = _localSetting.country;
      setCountryInfo(_country);
      setBusiness(_business);
      setBranch(_branch);
      if (!UserHelper.IsStateValid(location.state, navigate)) return;

      var startDT = Utils.ConvertToDate(location.state.startDT);
      if (!Utils.IsNullOrEmpty(location.state.currentStart)) {
        startDT = Utils.ConvertToDate(location.state.currentStart);
      }
      var endDT = Utils.ConvertToDate(location.state.endDT);
      if (!Utils.IsNullOrEmpty(location.state.currentEnd)) {
        endDT = Utils.ConvertToDate(location.state.currentEnd);
      }
      setSelectedDate([startDT, endDT]);
      await getAllGroupedVisits(
        Utils.GetISODateTimeString(startDT),
        Utils.GetISODateTimeString(endDT),
        _business.id,
        _branch.branchId
      );
    }
  };

  const onDateChange = async (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setAlertMessage(
          "Please select a valid date range."
        );
        setShowAlert(true);
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setAlert(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );
        } else {
          setSelectedDate(dateArray);
          await getAllGroupedVisits(
            Utils.GetISODateTimeString(dateArray[0]),
            Utils.GetISODateTimeString(dateArray[1]),
            business.id,
            branch.branchId
          );
        }
      }
    }
  };

  const setAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };

  const getAllGroupedVisits = async (startDT, endDT, businessId, branchId) => {
    setIsLoading(true);
    let response = await postAsync(
      "GroupedVisit/GetCombinedVisits",
      "",
      "",
      "",
      "GroupedVisitsByPeriod",
      [businessId, branchId, startDT, endDT]
    );
    setIsLoading(false);
    if (response.error) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      setAlert(response.errorMessage);
      setAllGroupedVisits([]);
      return;
    }
    setAllGroupedVisits(response.data);
  };

  useEffect(() => {
    handleAllGroupedVisits(selectedFilter);
  }, [allGroupedVisits]);

  const handleAllGroupedVisits = (fType) => {
    if (Utils.IsNullOrEmptyArray(allGroupedVisits)) {
      setFilteredGroupedVisits([]);
      return;
    }
    if (Utils.IsNullOrEmpty(fType)) {
      fType = selectedFilter;
      if (Utils.IsNullOrEmpty(fType)) {
        fType = filterTypes.All;
      }
    }
    switch (fType) {
      default:
      case filterTypes.All:
        setFilteredGroupedVisits(allGroupedVisits);
        break;
      case filterTypes.Paid:
        setFilteredGroupedVisits(
          allGroupedVisits.filter(
            (a) => a.groupedVisitStatus === GroupedVisitStatuses.PaymentReceived
          )
        );
        break;
      case filterTypes.Unpaid:
        setFilteredGroupedVisits(
          allGroupedVisits.filter(
            (a) => a.groupedVisitStatus === GroupedVisitStatuses.Pending
          )
        );
        break;
    }
  };

  const onChangeFilterType = (newType) => {
    handleAllGroupedVisits(newType);
    setSelectedFilter(newType);
  };

  const newVisit = () => {
    var newState = {
      ...location.state,
      currentStart: Utils.GetISODateTimeString(selectedDate[0]),
      currentEnd: Utils.GetISODateTimeString(selectedDate[1]),
    };
    navigate(GenerateGroupedVisitPage.Path, { state: newState });
  };

  const edit = (groupedVisit) => {
    if (Utils.IsNullOrEmptyObject(groupedVisit)) {
      return;
    }
    var newState = {
      ...location.state,
      currentStart: Utils.GetISODateTimeString(selectedDate[0]),
      currentEnd: Utils.GetISODateTimeString(selectedDate[1]),
      groupedVisit: groupedVisit,
    };
    navigate(GenerateGroupedVisitPage.Path, { state: newState });
  };

  const voidVisit = async (groupedVisit) => {
    if (Utils.IsNullOrEmptyObject(groupedVisit)) {
      return;
    }
    setIsLoading(true);
    let response = await postAsync(
      "GroupedVisit/Void",
      groupedVisit.id,
      "",
      "",
      "GroupedVisitsByPeriod",
      [
        business.id,
        branch.branchId,
        Utils.GetISODateTimeString(selectedDate[0]),
        Utils.GetISODateTimeString(selectedDate[1]),
      ]
    );
    setIsLoading(false);
    if (response.error) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      setAlert(response.errorMessage);
      setAllGroupedVisits([]);
      return;
    }
    setAllGroupedVisits(response.data);
  };

  const backNavigation = () => {
    navigate(OverviewPage.Path, {
      state: { startDT: location.state.startDT, endDT: location.state.endDT },
    });
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const refresh = async () => {
    await getAllGroupedVisits(
      Utils.GetISODateTimeString(selectedDate[0]),
      Utils.GetISODateTimeString(selectedDate[1]),
      business.id,
      branch.branchId
    );
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      {/* Loader */}
      <Loader open={isLoading} />

      {/* Message Popup */}
      <MessagePopup
        msgOpen={showAlert}
        msgText={alertMessage}
        onMsgClose={handleAlertClose}
      />

      {/* Main */}
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Overview} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <Grid flex="1" spacing={2} padding="20px">
                {/*Title*/}
                <Grid
                  container
                  className="title-sec"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    <Button
                      onClick={() => backNavigation()}
                      className="back-btn"
                    >
                      <NavigateBeforeIcon />
                    </Button>
                    Grouped Visits
                  </Typography>
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{ marginRight: "10px" }}
                    onClick={() => newVisit()}
                  >
                    Add New
                  </Button>
                </Grid>
                {/*Content */}
                <Grid
                  container
                  xs={12}
                  className="p-lg-2"
                  direction="column"
                  spacing={2}
                >
                  <Stack
                    direction="column"
                    alignItems="stretch"
                    justifyContent="center"
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="font-size-16"
                          onChange={(e) => onChangeFilterType(e.target.value)}
                        >
                          <FormControlLabel
                            value={filterTypes.All}
                            control={
                              <Radio
                                checked={selectedFilter === filterTypes.All}
                              />
                            }
                            label={filterTypes.All}
                          />
                          <FormControlLabel
                            value={filterTypes.Paid}
                            control={
                              <Radio
                                checked={selectedFilter === filterTypes.Paid}
                              />
                            }
                            label={filterTypes.Paid}
                          />
                          <FormControlLabel
                            value={filterTypes.Unpaid}
                            control={
                              <Radio
                                checked={selectedFilter === filterTypes.Unpaid}
                              />
                            }
                            label={filterTypes.Unpaid}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <RefreshIcon
                        sx={{ fontSize: 24, marginRight: "10px" }}
                        onClick={async () => await refresh()}
                      />
                      <Grid className="page-title text-center text-right">
                        <DateRangePicker
                          showLeadingZeros={true}
                          format="dd/MM/yyyy"
                          onChange={onDateChange}
                          maxDate={new Date()}
                          value={selectedDate}
                          locale={countryInfo.Culture}
                          clearIcon={null}
                          calendarIcon={
                            <CalendarMonthOutlinedIcon
                              style={{
                                "font-size": "24px",
                                "align-self": "center",
                                color: "#666666",
                              }}
                            />
                          }
                        />
                      </Grid>
                    </Stack>
                  </Stack>
                  <TableContainer className="visit-table" component={Paper}>
                    <Table stickyHeader area-lang="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "200px" }}>
                            Description
                          </TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Visits</TableCell>
                          <TableCell>Date & Time</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell className="text-right">Amount</TableCell>
                          <TableCell
                            style={{ width: "0px", padding: "0" }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!Utils.IsNullOrEmptyArray(filteredGroupedVisits) &&
                          filteredGroupedVisits.map((groupedVisit) => (
                            <TableRow>
                              <TableCell
                                onClick={() => edit(groupedVisit)}
                                style={{ width: "200px" }}
                              >
                                {groupedVisit.description}
                              </TableCell>
                              <TableCell onClick={() => edit(groupedVisit)}>
                                {groupedVisit.customerDesc}
                              </TableCell>
                              <TableCell onClick={() => edit(groupedVisit)}>
                                {groupedVisit.visitInformations.length}
                              </TableCell>
                              <TableCell onClick={() => edit(groupedVisit)}>
                                {moment(groupedVisit.createdDT).format(
                                  "MMM DD, YYYY hh:mm"
                                )}
                              </TableCell>
                              <TableCell onClick={() => edit(groupedVisit)}>
                                {groupedVisit.groupedVisitStatus ===
                                  GroupedVisitStatuses.PaymentReceived
                                  ? "Paid"
                                  : groupedVisit.groupedVisitStatus}
                              </TableCell>
                              <TableCell className="text-right" onClick={() => edit(groupedVisit)}>
                                <AmountTypography
                                  value={groupedVisit.totalPaymentAmount}
                                  country={countryInfo}
                                />
                              </TableCell>
                              <TableCell style={{ padding: "0" }}>
                                <div className="menuList">
                                  <ul>
                                    {groupedVisit.groupedVisitStatus ===
                                      GroupedVisitStatuses.Pending && (
                                        <li
                                          onClick={(e) => voidVisit(groupedVisit)}
                                        >
                                          <DeleteOutlineIcon
                                            fontSize="large"
                                            style={{ color: "#ff0000" }}
                                          />
                                          <span>Void</span>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default GroupedVisits;
